@import url(//fonts.googleapis.com/css?family=Roboto:300,400,400italic,500,700);
/**
 * Spinners (Loader.CSS)
 */
.loader-primary .ball-pulse > div,
.loader-primary .ball-grid-pulse > div,
.loader-primary .square-spin > div,
.loader-primary .ball-pulse-rise > div,
.loader-primary .ball-rotate > div,
.loader-primary .ball-rotate > div:before, .loader-primary .ball-rotate > div:after,
.loader-primary .cube-transition > div,
.loader-primary .ball-zig-zag > div,
.loader-primary .ball-zig-zag-deflect > div,
.loader-primary .ball-triangle-path > div,
.loader-primary .ball-scale > div,
.loader-primary .line-scale > div,
.loader-primary .line-scale-party > div,
.loader-primary .ball-scale-multiple > div,
.loader-primary .ball-pulse-sync > div,
.loader-primary .ball-beat > div,
.loader-primary .line-scale-pulse-out > div,
.loader-primary .line-scale-pulse-out-rapid > div,
.loader-primary .ball-spin-fade-loader > div,
.loader-primary .line-spin-fade-loader > div,
.loader-primary .triangle-skew-spin > div,
.loader-primary .ball-grid-beat > div,
.loader-primary .semi-circle-spin > div {
  background-color: #448AFF; }

.loader-primary .ball-clip-rotate-multiple {
  width: 1px; }
  .loader-primary .ball-clip-rotate-multiple > div {
    border-color: #448AFF transparent #448AFF transparent; }
    .loader-primary .ball-clip-rotate-multiple > div:last-child {
      border-color: transparent #448AFF transparent #448AFF; }

.loader-primary .ball-clip-rotate > div {
  border-color: #448AFF;
  border-bottom-color: transparent; }

.loader-primary .ball-clip-rotate-pulse > div:first-child {
  background-color: #448AFF;
  top: 5px;
  left: -8px; }

.loader-primary .ball-clip-rotate-pulse > div:last-child {
  border-color: #448AFF transparent #448AFF transparent; }

.loader-primary .square-spin > div,
.loader-primary .ball-scale-ripple > div,
.loader-primary .ball-scale-ripple-multiple > div {
  border-color: #448AFF; }

.loader-primary .pacman > div:first-of-type {
  border-top-color: #448AFF;
  border-left-color: #448AFF;
  border-bottom-color: #448AFF; }

.loader-primary .pacman > div:nth-child(2) {
  border-top-color: #448AFF;
  border-left-color: #448AFF;
  border-bottom-color: #448AFF; }

.loader-primary .pacman > div:nth-child(3), .loader-primary .pacman > div:nth-child(4), .loader-primary .pacman > div:nth-child(5), .loader-primary .pacman > div:nth-child(6) {
  background-color: #448AFF; }

.loader {
  display: inline-block; }

.loader-demo {
  padding: 20px 0;
  height: 120px;
  text-align: center; }
  .loader-demo > .loader-inner {
    display: inline-block; }

/**
 * Main Layout
 */
html, body, #app {
  width: 100%;
  height: 100%;
  background-color: #f1f2f3;
  min-width: 320px;
  direction: ltr; }

body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #6C7177;
  font-size: 0.875rem;
  line-height: 1.5; }

.layout-container {
  position: relative;
  width: 100%;
  height: 100%; }

.page-container, .page-container > div {
  width: 100%;
  height: 100%; }

.main-container {
  position: relative;
  height: calc(100% - 65px);
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  .main-container > section, .main-container .section-container {
    min-height: calc(100vh - 60px - 65px); }
  .main-container > div, .main-container > div > div {
    min-height: calc(100% - 65px); }

.content-heading {
  padding: 16px;
  box-shadow: 0 1px 0 rgba(20, 20, 20, 0.075); }

footer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  border-top: 1px solid rgba(160, 160, 160, 0.12);
  padding: 20px;
  z-index: 109; }

.container-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .container-full > .row {
    margin: 0; }
    .container-full > .row > [class*="col-"]:first-child {
      padding-left: 0; }
    .container-full > .row > [class*="col-"]:last-child {
      padding-right: 0; }

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  padding: 8px;
  width: auto; }
  @media only screen and (min-width: 480px) {
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xs {
      padding: 16px; } }
  @media only screen and (min-width: 992px) {
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xs {
      padding: 24px; } }

.container-fluid {
  max-width: none; }

.container-lg {
  max-width: 1200px; }

.container-md {
  max-width: 992px; }

.container-sm {
  max-width: 768px; }

.container-xs {
  max-width: 480px; }

@media only screen and (max-width: 1199px) {
  .container-unwrap {
    padding: 0; }
    .container-unwrap > .row {
      margin: 0; }
      .container-unwrap > .row > [class*="col-"] {
        padding-left: 0;
        padding-right: 0; }
    .container-unwrap .cardbox {
      margin-bottom: 0; } }

.container-overlap {
  position: relative;
  padding: 32px 16px 64px 16px; }
  .container-overlap + .container-fluid,
  .container-overlap + .container-lg,
  .container-overlap + .container-md,
  .container-overlap + .container-sm,
  .container-overlap + .container-xs {
    padding-top: 0;
    margin-top: -32px; }
    .container-overlap + .container-fluid .push-down,
    .container-overlap + .container-lg .push-down,
    .container-overlap + .container-md .push-down,
    .container-overlap + .container-sm .push-down,
    .container-overlap + .container-xs .push-down {
      display: block;
      height: 48px; }
  .container-overlap:before {
    background-color: inherit;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }

/**
 * Layout Variants
 */
.sidebar-offcanvas .sidebar-container {
  top: 65px;
  transform: translate3d(-100%, 0, 0); }
  .sidebar-offcanvas .sidebar-container .sidebar-header {
    box-shadow: 0 0 0 #000; }

.sidebar-offcanvas .header-container {
  z-index: 30; }

.sidebar-offcanvas .main-container,
.sidebar-offcanvas .header-container {
  margin-left: 0; }

.sidebar-offcanvas.offcanvas-visible .sidebar-container {
  transform: translate3d(0, 0, 0); }

.no-csstransforms3d .sidebar-offcanvas .sidebar-offcanvas .sidebar-container {
  margin-left: -240px; }

.no-csstransforms3d .sidebar-offcanvas .sidebar-offcanvas.offcanvas-visible .sidebar-container {
  margin-left: 0; }

/**
 * Header
 */
.header-container {
  position: relative;
  background-color: #fff;
  z-index: 1; }
  .header-container > nav {
    min-height: 65px;
    line-height: 64px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14); }
    .header-container > nav::after {
      display: block;
      clear: both;
      content: ""; }
    .header-container > nav > .header-title {
      display: none;
      position: relative;
      float: left;
      margin: 0;
      padding: 0 16px;
      font-size: 20px;
      line-height: inherit;
      letter-spacing: .02em;
      font-weight: 400; }
      @media (min-width: 340px) {
        .header-container > nav > .header-title {
          display: block; } }
      @media (max-width: 420px) {
        .header-container > nav > .header-title {
          max-width: 140px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; } }
    .header-container > nav > ul {
      float: left;
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .header-container > nav > ul::after {
        display: block;
        clear: both;
        content: ""; }
      .header-container > nav > ul > li {
        float: left; }
        .header-container > nav > ul > li > a {
          display: block;
          padding: 0 16px;
          text-decoration: none;
          color: inherit; }
          .header-container > nav > ul > li > a > span > em,
          .header-container > nav > ul > li > a > em {
            font-size: 24px;
            vertical-align: -24%;
            line-height: 1; }
      .header-container > nav > ul > li > a:hover {
        background-color: rgba(0, 0, 0, 0.025); }

#offcanvas-toggler {
  display: none; }

.sidebar-offcanvas #offcanvas-toggler {
  display: block; }

.header-form-search {
  position: relative;
  margin: 0;
  overflow: hidden;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .header-form-search .btn,
  .header-form-search .form-control {
    margin: 0;
    border: 0;
    background-color: transparent;
    box-shadow: 0 0 0 #000; }
  .header-form-search .btn {
    padding-top: 0;
    padding-bottom: 0; }
  .header-form-search .form-control:focus + .focus-bottom-line {
    left: 40px;
    right: 40px;
    visibility: visible; }
  .header-form-search .focus-bottom-line {
    content: '';
    position: absolute;
    bottom: 0;
    left: 45%;
    right: 45%;
    height: 2px;
    visibility: hidden;
    background-color: rgba(120, 120, 120, 0.15);
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

/**
 * Header menu links (animated hamburguer toggles)
 */
.header-container > nav > ul > li > .menu-link {
  height: 65px;
  padding-top: 25px; }

.menu-link-close {
  display: block;
  position: relative; }
  .menu-link-close span {
    position: relative;
    display: block; }
  .menu-link-close span,
  .menu-link-close span:before,
  .menu-link-close span:after {
    width: 18px;
    height: 0;
    border-bottom: 2px solid;
    will-change: transform;
    transition: transform 0.4s; }
  .menu-link-close span {
    transition: border-color 0.4s; }
  .menu-link-close span:before,
  .menu-link-close span:after {
    content: " ";
    position: absolute; }
  .menu-link-close span:before {
    top: 6px; }
  .menu-link-close span:after {
    top: 12px; }

.active .menu-link-close span {
  border-color: transparent; }

.active .menu-link-close span:before {
  transform: rotate(45deg) translate(1px, 0px); }

.active .menu-link-close span:after {
  transform: rotate(-45deg) translate(4px, -3px); }

.menu-link-slide {
  display: block;
  position: relative; }
  .menu-link-slide span {
    position: relative;
    display: block; }
  .menu-link-slide span,
  .menu-link-slide span:before,
  .menu-link-slide span:after {
    width: 18px;
    height: 0;
    border-bottom: 2px solid;
    transition: all 0.4s; }
  .menu-link-slide span:before,
  .menu-link-slide span:after {
    content: " ";
    position: absolute; }
  .menu-link-slide span:before {
    top: 7px;
    left: 0;
    width: 60%;
    will-change: left; }
  .menu-link-slide span:after {
    top: 14px; }
  .menu-link-slide em {
    position: absolute;
    top: 4px;
    right: 0;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid;
    transition: all 0.4s;
    will-change: right, transform; }

.active .menu-link-slide span:before {
  left: 40%; }

.active .menu-link-slide span em {
  right: 80%;
  transform: rotate(180deg); }

.menu-link-arrow {
  display: block;
  position: relative; }
  .menu-link-arrow span {
    position: relative;
    display: block;
    will-change: transform;
    height: 14px;
    width: 18px;
    transition: transform 0.4s; }
  .menu-link-arrow em,
  .menu-link-arrow em:before,
  .menu-link-arrow em:after {
    content: " ";
    position: absolute;
    display: block;
    width: 18px;
    height: 0;
    border-bottom: 2px solid;
    will-change: transform;
    transition: transform 0.4s; }
  .menu-link-arrow em {
    transition: border-color 0.4s; }
  .menu-link-arrow em:before {
    top: -6px; }
  .menu-link-arrow em {
    top: 6px; }
  .menu-link-arrow em:after {
    top: 6px; }

.active .menu-link-arrow span {
  transform: rotate(180deg); }
  .active .menu-link-arrow span em:before {
    width: 12px;
    transform: translateX(6px) translateY(1px) rotate(45deg);
    -webkit-transform: translateX(6px) translateY(1px) rotate(45deg); }
  .active .menu-link-arrow span em:after {
    width: 12px;
    transform: translateX(6px) translateY(-1px) rotate(-45deg);
    -webkit-transform: translateX(6px) translateY(-1px) rotate(-45deg); }

/**
 * Sidebar
 */
.sidebar-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 240px;
  overflow-y: auto;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  background-color: #263238;
  color: rgba(255, 255, 255, 0.75);
  z-index: 20; }
  .sidebar-container a.sb-link {
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none; }

.span-user {
  color: #6c7177;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #6C7177;
  letter-spacing: 0.0125em;
  font-weight: 500; }

.sidebar-header {
  height: 65px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14); }
  .sidebar-header > .sidebar-header-logo {
    display: inline-block;
    height: 100%;
    line-height: 65px;
    text-decoration: none;
    color: inherit;
    font-size: 22px;
    letter-spacing: .023em; }
    .sidebar-header > .sidebar-header-logo > img,
    .sidebar-header > .sidebar-header-logo > svg {
      margin-left: 50%;
      margin-right: 50%;
      display: inline-block;
      width: 100px;
      height: 24px;
      vertical-align: -5%; }
      .sidebar-header > .sidebar-header-logo > img + .sidebar-header-logo-text,
      .sidebar-header > .sidebar-header-logo > svg + .sidebar-header-logo-text {
        margin-left: 50%;
        margin-right: 50%; }
    .sidebar-header > .sidebar-header-logo > svg {
      fill: #fff; }
  .sidebar-header > .sidebar-header-logo-text {
    font-weight: 500;
    font-size: 20px;
    vertical-align: middle; }

.sidebar-header + .sidebar-content {
  height: calc(100% - 65px);
  overflow: auto; }

.sidebar-content {
  border-right: 1px solid rgba(0, 0, 0, 0.11); }

.sidebar-toolbar {
  position: relative;
  z-index: -1;
  background-color: inherit;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100%;
  padding: 20px 20px; }
  .sidebar-toolbar + .sidebar-nav {
    padding-top: 0; }

.sidebar-container {
  transform: translate3d(-100%, 0, 0);
  transition: transform .28s ease-in-out;
  will-change: transform; }

.sidebar-layout-obfuscator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
  will-change: opacity;
  transition: opacity .28s ease-in-out, visibility .28s ease-in-out; }

#show-sidebar, #hide-sidebar {
  cursor: pointer; }

.back-btn {
  color: white;
  margin: auto;
  margin: 23px; }

.active-sidebar {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  transform: translate3d(0, 0, 0); }

.active-body {
  opacity: 1;
  visibility: visible; }

@media only screen and (max-width: 991px) {
  .sidebar-visible {
    overflow: hidden; }
    .sidebar-visible .sidebar-container {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
      transform: translate3d(0, 0, 0); }
    .sidebar-visible .sidebar-layout-obfuscator {
      opacity: 1;
      visibility: visible; } }

.no-csstransforms3d .sidebar-container {
  margin-left: -240px; }
  @media only screen and (min-width: 992px) {
    .no-csstransforms3d .sidebar-container {
      margin-left: 0; } }

@media only screen and (max-width: 1199px) {
  .no-csstransforms3d .sidebar-visible .sidebar-container {
    margin-left: 0; } }

.sidebar-nav {
  padding-top: 16px;
  font-weight: 500;
  background-color: inherit; }
  .sidebar-nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .sidebar-nav ul > li > a {
      display: block;
      padding: 0 16px;
      height: 50px;
      line-height: 50px;
      text-decoration: none;
      letter-spacing: 0.0125em;
      color: inherit;
      overflow: hidden; }
      .sidebar-nav ul > li > a > .nav-icon {
        position: relative;
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-right: 16px;
        text-align: center;
        font-size: 24px;
        vertical-align: middle; }
        .sidebar-nav ul > li > a > .nav-icon > em,
        .sidebar-nav ul > li > a > .nav-icon > img,
        .sidebar-nav ul > li > a > .nav-icon > svg {
          position: absolute;
          display: inline-block;
          color: inherit;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          line-height: 1;
          color: #31569F;
          fill: #31569F; }
      .sidebar-nav ul > li > a > .nav-label,
      .sidebar-nav ul > li > a > .nav-caret {
        line-height: inherit; }
      .sidebar-nav ul > li > a > .nav-label {
        margin-right: 16px;
        margin-top: -2px; }
      .sidebar-nav ul > li > a > .nav-caret {
        margin-left: 11px; }
        .sidebar-nav ul > li > a > .nav-caret + .nav-label {
          margin-right: 0; }
    .sidebar-nav ul > li > a:hover,
    .sidebar-nav ul > li > a:focus {
      background-color: rgba(162, 162, 162, 0.09); }
      .sidebar-nav ul > li > a:hover > .nav-icon em,
      .sidebar-nav ul > li > a:focus > .nav-icon em {
        color: #448AFF; }
    .sidebar-nav ul > li.active {
      background-color: rgba(162, 162, 162, 0.026); }
      .sidebar-nav ul > li.active > a > .nav-icon em {
        color: #448AFF; }
      .sidebar-nav ul > li.active > ul li.active a {
        background-color: rgba(162, 162, 162, 0.026); }
    .sidebar-nav ul > li ul > li > a {
      padding-left: 72px; }
    .sidebar-nav ul > li ul > li ul > li > a {
      padding-left: 88px; }
    .sidebar-nav ul > li > ul {
      height: 0;
      overflow: hidden;
      transition: height 0.55s cubic-bezier(0.35, 0, 0.25, 1); }
      .sidebar-nav ul > li > ul > li > a {
        margin-left: -20px;
        opacity: 0;
        transition: .5s ease;
        transition-property: opacity, margin-left; }
    .sidebar-nav ul > li > a > .nav-caret {
      transition-transform: .2s ease; }
    .sidebar-nav ul > li.active > ul {
      height: auto; }
      .sidebar-nav ul > li.active > ul > li > a {
        opacity: 1;
        margin-left: 0; }
    .sidebar-nav ul > li.active > a > .nav-caret {
      transform: rotate(90deg); }

/**
 * Utilities
 */
.m0 {
  margin: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.m {
  margin: 16px !important; }

.ml, .mh {
  margin-left: 16px !important; }

.mr, .mh {
  margin-right: 16px !important; }

.mt, .mv {
  margin-top: 16px !important; }

.mb, .mv {
  margin-bottom: 16px !important; }

.m-sm {
  margin: 8px !important; }

.ml-sm, .mh-sm {
  margin-left: 8px !important; }

.mr-sm, .mh-sm {
  margin-right: 8px !important; }

.mt-sm, .mv-sm {
  margin-top: 8px !important; }

.mb-sm, .mv-sm {
  margin-bottom: 8px !important; }

.m-lg {
  margin: 24px !important; }

.ml-lg, .mh-lg {
  margin-left: 24px !important; }

.mr-lg, .mh-lg {
  margin-right: 24px !important; }

.mt-lg, .mv-lg {
  margin-top: 24px !important; }

.mb-lg, .mv-lg {
  margin-bottom: 24px !important; }

.m-xl {
  margin: 32px !important; }

.ml-xl, .mh-xl {
  margin-left: 32px !important; }

.mr-xl, .mh-xl {
  margin-right: 32px !important; }

.mt-xl, .mv-xl {
  margin-top: 32px !important; }

.mb-xl, .mv-xl {
  margin-bottom: 32px !important; }

.p0 {
  padding: 0 !important; }

.pl0, .ph0 {
  padding-left: 0 !important; }

.pr0, .ph0 {
  padding-right: 0 !important; }

.pt0, .pv0 {
  padding-top: 0 !important; }

.pb0, .pv0 {
  padding-bottom: 0 !important; }

.p {
  padding: 16px !important; }

.pl, .ph {
  padding-left: 16px !important; }

.pr, .ph {
  padding-right: 16px !important; }

.pt, .pv {
  padding-top: 16px !important; }

.pb, .pv {
  padding-bottom: 16px !important; }

.p-sm {
  padding: 4px !important; }

.pl-sm, .ph-sm {
  padding-left: 4px !important; }

.pr-sm, .ph-sm {
  padding-right: 4px !important; }

.pt-sm, .pv-sm {
  padding-top: 4px !important; }

.pb-sm, .pv-sm {
  padding-bottom: 4px !important; }

.p-lg {
  padding: 24px !important; }

.pl-lg, .ph-lg {
  padding-left: 24px !important; }

.pr-lg, .ph-lg {
  padding-right: 24px !important; }

.pt-lg, .pv-lg {
  padding-top: 24px !important; }

.pb-lg, .pv-lg {
  padding-bottom: 24px !important; }

.p-xl {
  padding: 32px !important; }

.pl-xl, .ph-xl {
  padding-left: 32px !important; }

.pr-xl, .ph-xl {
  padding-right: 32px !important; }

.pt-xl, .pv-xl {
  padding-top: 32px !important; }

.pb-xl, .pv-xl {
  padding-bottom: 32px !important; }

.fh {
  height: 100% !important; }

@media only screen and (min-width: 768px) {
  .fh-sm {
    height: 100% !important; } }

@media only screen and (min-width: 992px) {
  .fh-md {
    height: 100% !important; } }

@media only screen and (min-width: 1200px) {
  .fh-lg {
    height: 100% !important; } }

.fw {
  width: 100% !important; }

@media only screen and (min-width: 768px) {
  .fw-sm {
    width: 100% !important; } }

@media only screen and (min-width: 992px) {
  .fw-md {
    width: 100% !important; } }

@media only screen and (min-width: 1200px) {
  .fw-lg {
    width: 100% !important; } }

.va-middle {
  vertical-align: middle !important; }

.va-top {
  vertical-align: top !important; }

.va-bottom {
  vertical-align: bottom !important; }

table.va-middle td {
  vertical-align: middle !important; }

table.va-top td {
  vertical-align: top !important; }

table.va-bottom td {
  vertical-align: bottom !important; }

.b0 {
  border-width: 0 !important; }

.bl0 {
  border-left-width: 0 !important; }

.br0 {
  border-right-width: 0 !important; }

.bt0 {
  border-top-width: 0 !important; }

.bb0 {
  border-bottom-width: 0 !important; }

.br, .b, .ba {
  border-right: 1px solid rgba(162, 162, 162, 0.32); }

.bl, .b, .ba {
  border-left: 1px solid rgba(162, 162, 162, 0.32); }

.bt, .b, .ba {
  border-top: 1px solid rgba(162, 162, 162, 0.32); }

.bb, .b, .ba {
  border-bottom: 1px solid rgba(162, 162, 162, 0.32); }

.spr {
  margin-right: .25em; }

.spl {
  margin-left: .25em; }

.text-white {
  color: #fff; }

.text-alpha {
  color: rgba(255, 255, 255, 0.5); }

.text-alpha-inverse {
  color: rgba(0, 0, 0, 0.5); }

.text-gray-darker {
  color: #263238; }

.text-gray-dark {
  color: #455A64; }

.text-gray {
  color: #607D8B; }

.text-gray-light {
  color: #90A4AE; }

.text-gray-lighter {
  color: #ECEFF1; }

.text-inherit {
  color: inherit !important; }

.text-soft {
  color: rgba(162, 162, 162, 0.66); }

.text-light {
  color: rgba(255, 255, 255, 0.74); }

.text-sm {
  font-size: 0.74375rem; }

.text-md {
  font-size: 1.6625rem; }

.text-lg {
  font-size: 2.625rem; }

.lh1 {
  line-height: 1; }

.text-nowrap {
  white-space: nowrap; }

.text-thin {
  font-weight: 100 !important; }

.text-bold {
  font-weight: bold !important; }

.text-normal {
  font-weight: normal !important; }

.text-normal p {
  font-weight: normal; }

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom; }

.inline {
  display: inline-block !important; }

.block-center {
  margin: 0 auto; }

.block {
  display: block !important; }

.bg-white {
  background-color: #fff;
  color: inherit !important; }

.bg-primary {
  background-color: #448AFF !important;
  color: #fff !important; }

.bg-primary .text-muted {
  color: rgba(255, 255, 255, 0.5) !important; }

.bg-primary small {
  color: inherit; }

.bg-success {
  background-color: #4CAF50 !important;
  color: #fff !important; }

.bg-success .text-muted {
  color: rgba(255, 255, 255, 0.5) !important; }

.bg-success small {
  color: inherit; }

.bg-info {
  background-color: #03A9F4 !important;
  color: #fff !important; }

.bg-info .text-muted {
  color: rgba(255, 255, 255, 0.5) !important; }

.bg-info small {
  color: inherit; }

.bg-warning {
  background-color: #FFB300 !important;
  color: #fff !important; }

.bg-warning .text-muted {
  color: rgba(255, 255, 255, 0.5) !important; }

.bg-warning small {
  color: inherit; }

.bg-danger {
  background-color: #F44336 !important;
  color: #fff !important; }

.bg-danger .text-muted {
  color: rgba(255, 255, 255, 0.5) !important; }

.bg-danger small {
  color: inherit; }

.bg-gray-darker {
  background-color: #263238;
  color: #fff !important; }

.bg-gray-dark {
  background-color: #455A64;
  color: #fff !important; }

.bg-gray {
  background-color: #607D8B;
  color: #6C7177 !important; }

.bg-gray-light {
  background-color: #90A4AE;
  color: #6C7177 !important; }

.bg-gray-lighter {
  background-color: #ECEFF1;
  color: #6C7177 !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-fade {
  background-color: transparent !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 55%, black);
  background-repeat: no-repeat; }

.bg-cover {
  background-size: cover; }

.bg-full {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; }

.bg-center {
  background-position: center center;
  background-size: cover; }

.thumb8 {
  width: 8px !important;
  height: 8px !important; }

.thumb16 {
  width: 16px !important;
  height: 16px !important; }

.thumb24 {
  width: 24px !important;
  height: 24px !important; }

.thumb32 {
  width: 32px !important;
  height: 32px !important; }

.thumb40 {
  width: 40px !important;
  height: 40px !important; }

.thumb48 {
  width: 48px !important;
  height: 48px !important; }

.thumb64 {
  width: 64px !important;
  height: 64px !important; }

.thumb80 {
  width: 80px !important;
  height: 80px !important; }

.thumb96 {
  width: 96px !important;
  height: 96px !important; }

.thumb128 {
  width: 128px !important;
  height: 128px !important; }

.initial8 {
  width: 8px !important;
  height: 8px !important;
  line-height: 8px;
  text-align: center;
  border-radius: 50%; }

.initial16 {
  width: 16px !important;
  height: 16px !important;
  line-height: 16px;
  text-align: center;
  border-radius: 50%; }

.initial24 {
  width: 24px !important;
  height: 24px !important;
  line-height: 24px;
  text-align: center;
  border-radius: 50%; }

.initial32 {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px;
  text-align: center;
  border-radius: 50%; }

.initial40 {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px;
  text-align: center;
  border-radius: 50%; }

.initial48 {
  width: 48px !important;
  height: 48px !important;
  line-height: 48px;
  text-align: center;
  border-radius: 50%; }

.initial64 {
  width: 64px !important;
  height: 64px !important;
  line-height: 64px;
  text-align: center;
  border-radius: 50%; }

.initial80 {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px;
  text-align: center;
  border-radius: 50%; }

.initial96 {
  width: 96px !important;
  height: 96px !important;
  line-height: 96px;
  text-align: center;
  border-radius: 50%; }

.initial128 {
  width: 128px !important;
  height: 128px !important;
  line-height: 128px;
  text-align: center;
  border-radius: 50%; }

.table-fixed {
  table-layout: fixed;
  width: 100%; }

.shadow-clear {
  box-shadow: 0 0 0 #000 !important; }

.radius-clear {
  border-radius: 0 !important; }

.input-rounded {
  border-radius: 100px; }

.item-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab; }

.item-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing; }

.clickable {
  cursor: pointer !important; }

.rel-wrapper {
  position: relative !important; }

.oh {
  overflow: hidden; }

.oa {
  overflow: auto; }

.icon-18 {
  font-size: 18px;
  vertical-align: -18%; }

.icon-24 {
  font-size: 24px;
  vertical-align: -24%; }

.icon-36 {
  font-size: 36px;
  vertical-align: -36%; }

.icon-48 {
  font-size: 48px;
  vertical-align: -48%; }

.icon-2x {
  font-size: 2em; }

.icon-3x {
  font-size: 3em; }

.icon-4x {
  font-size: 4em; }

.icon-5x {
  font-size: 5em; }

.icon-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.icon-fw {
  display: inline-block;
  width: 1.28571429em;
  text-align: center; }

.icon-dark {
  color: rgba(0, 0, 0, 0.54); }
  .icon-dark.icon-inactive {
    color: rgba(0, 0, 0, 0.26); }

.icon-light {
  color: white; }
  .icon-light.icon-inactive {
    color: rgba(255, 255, 255, 0.3); }

.wd-xxs {
  width: 60px; }

.wd-xs {
  width: 90px; }

.wd-sm {
  width: 150px; }

.wd-sd {
  width: 200px; }

.wd-md {
  width: 240px; }

.wd-lg {
  width: 280px; }

.wd-xl {
  width: 320px; }

.wd-xxl {
  width: 360px; }

.wd-wide {
  width: 100%; }

.wd-auto {
  width: auto; }

.wd-zero {
  width: 0; }

.link-unstyled {
  text-decoration: none !important;
  outline: none !important; }

textarea.no-resize {
  resize: none;
  max-width: 100%;
  min-width: 100%; }

.shadow-z0 {
  box-shadow: 0 0 0 #000; }

.shadow-z1 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014); }

.shadow-z2 {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.014); }

.shadow-z3 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.14), 0 6px 10px 0 rgba(0, 0, 0, 0.098), 0 1px 18px 0 rgba(0, 0, 0, 0.014); }

.shadow-z4 {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.14), 0 8px 10px 1px rgba(0, 0, 0, 0.098), 0 3px 14px 2px rgba(0, 0, 0, 0.014); }

.shadow-z5 {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.14), 0 16px 24px 2px rgba(0, 0, 0, 0.098), 0 6px 30px 5px rgba(0, 0, 0, 0.014); }

.image-list > a {
  margin-left: -8px; }
  .image-list > a:first-child {
    margin-left: 0; }
  .image-list > a:last-of-type {
    margin-right: 8px; }

.list-group-unstyle > .list-group-item {
  border-left: 0;
  border-right: 0; }
  .list-group-unstyle > .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-unstyle > .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.icons-list {
  margin: 40px auto;
  padding-left: 0;
  width: 100%;
  list-style: none;
  text-align: center;
  font-size: 1px; }
  .icons-list > li {
    position: relative;
    z-index: 0;
    display: inline-block;
    padding: 22px;
    width: 84px;
    border-radius: 4px;
    list-style: none;
    text-align: center;
    font-weight: normal;
    font-size: 32px;
    cursor: pointer; }
    .icons-list > li:hover {
      background-color: rgba(255, 255, 255, 0.9);
      color: #03A9F4;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.014);
      transition: all .2s ease; }

.reader-block p {
  margin: 16px auto;
  font-size: 16px;
  line-height: 28px;
  font-weight: normal; }
  .reader-block p > img {
    max-width: 100%;
    margin: 32px auto; }

.box-placeholder {
  margin-bottom: 15px;
  padding: 20px;
  border: 1px dashed rgba(162, 162, 162, 0.26);
  background: transparent;
  color: inherit; }
  .box-placeholder > :last-child {
    margin-bottom: 0; }
  .box-placeholder.box-placeholder-lg {
    padding-top: 80px;
    padding-bottom: 80px; }

/**
 * Settings
 */
.setting-color {
  padding: 0 5px; }
  .setting-color > label {
    display: block;
    position: relative;
    height: 30px;
    margin: 0 10px;
    border-radius: 1px;
    cursor: pointer;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014); }
    .setting-color > label:first-child {
      margin-left: 0; }
    .setting-color > label:last-child {
      margin-right: 0; }
    .setting-color > label .t-grid, .setting-color > label .t-row {
      max-width: 60px;
      height: 100%;
      margin: 0; }
    .setting-color > label > .color {
      display: block;
      height: 18px; }
    .setting-color > label > .split {
      display: block; }
      .setting-color > label > .split::after {
        display: block;
        clear: both;
        content: ""; }
      .setting-color > label > .split > .color {
        display: block;
        height: 37.5px; }
        .setting-color > label > .split > .color:first-child {
          float: left;
          width: 70%; }
        .setting-color > label > .split > .color:last-child {
          float: right;
          width: 30%; }
    .setting-color > label > .ion-checkmark-round {
      position: absolute;
      display: block;
      right: -5px;
      top: -10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      text-align: center;
      font-size: 14px;
      color: #4CAF50;
      background-color: #fff;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014);
      transition: transform .2s ease;
      transform: scale(0); }
    .setting-color > label > input[type="radio"] {
      position: absolute;
      visibility: hidden; }
      .setting-color > label > input[type="radio"]:checked + .ion-checkmark-round {
        opacity: 1 !important;
        transform: scale(1); }

/**
 * Bootstrap Reset
 */
*:focus {
  outline: 0 !important; }

select, input, textarea {
  color: inherit;
  font: inherit; }

a, .btn-link {
  outline: none !important;
  color: #448AFF;
  transition: color .2s ease; }
  a.text-muted:hover, a.text-muted:focus, .btn-link.text-muted:hover, .btn-link.text-muted:focus {
    color: #898989 !important; }

address {
  margin-bottom: 2rem; }

small {
  font-size: 85%; }

.rounded {
  border-radius: 6px !important; }

.text-muted {
  color: #a2a2a2 !important; }

hr {
  border-top: 1px solid rgba(162, 162, 162, 0.36);
  margin-top: 2rem;
  margin-bottom: 2rem; }

code {
  margin-left: 1px;
  margin-right: 1px;
  -webkit-font-smoothing: auto;
  padding: 0.125em 0.35em;
  border-radius: 2px;
  font-size: inherit;
  background: rgba(0, 0, 0, 0.045);
  color: #106CC8; }

pre {
  background-color: rgba(162, 162, 162, 0.12);
  border-color: rgba(162, 162, 162, 0.26);
  color: inherit; }

.img-thumbnail {
  border-radius: 1px;
  border: 0;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.014); }

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid rgba(162, 162, 162, 0.06); }

.breadcrumb {
  font-weight: normal;
  border-radius: 0;
  color: #a2a2a2;
  padding: 20px 20px;
  background-color: #fff;
  border-bottom: 1px solid rgba(162, 162, 162, 0.16);
  margin-bottom: 2rem; }
  .breadcrumb .breadcrumb-item::before {
    color: #ccc; }

.btn {
  outline: none !important;
  border-radius: 2px;
  transition-property: background-color, color, box-shadow;
  transition-duration: .1s, .2s, .2s;
  transition-timing-function: ease-in-out;
  font-weight: 400;
  line-height: 1.25rem;
  font-family: inherit;
  padding: .375rem .75rem;
  font-size: .875rem; }
  .btn:active, .btn.active {
    box-shadow: 0 0 0 #000; }

.btn-group-sm > .btn, .btn-sm {
  padding: 0.3125rem 0.625rem;
  font-size: 0.75rem;
  line-height: 0.25rem;
  border-radius: 0.2rem;
  line-height: 1.5; }

.btn-lg {
  padding: 0.625rem 1rem;
  font-size: 1.125rem;
  line-height: 1.3;
  border-radius: 0.2rem;
  line-height: 1.34; }

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
  line-height: 1.5; }

.btn-purple {
  color: #fff;
  background-color: #7c3088;
  border-color: #7c3088; }
  .btn-purple:hover {
    color: #fff;
    background-color: #62266c;
    border-color: #5a2362; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: 0 0 0 2px rgba(124, 48, 136, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #7c3088;
    border-color: #7c3088; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #5a2362;
    border-color: #511f59; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(124, 48, 136, 0.5); }

.btn-blue {
  color: #fff;
  background-color: #31569F;
  border-color: #31569F; }
  .btn-blue:hover {
    color: #fff;
    background-color: #284682;
    border-color: #254178; }
  .btn-blue:focus, .btn-blue.focus {
    box-shadow: 0 0 0 2px rgba(49, 86, 159, 0.5); }
  .btn-blue.disabled, .btn-blue:disabled {
    color: #fff;
    background-color: #31569F;
    border-color: #31569F; }
  .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
  .show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #254178;
    border-color: #223c6e; }
    .btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(49, 86, 159, 0.5); }

.btn-orange {
  color: #fff;
  background-color: #FF8700;
  border-color: #FF8700; }
  .btn-orange:hover {
    color: #fff;
    background-color: #d97300;
    border-color: #cc6c00; }
  .btn-orange:focus, .btn-orange.focus {
    box-shadow: 0 0 0 2px rgba(255, 135, 0, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #212529;
    background-color: #FF8700;
    border-color: #FF8700; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #cc6c00;
    border-color: #bf6500; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(255, 135, 0, 0.5); }

.btn-primary {
  color: #fff;
  background-color: #448AFF;
  border-color: #448AFF; }
  .btn-primary:hover {
    color: #fff;
    background-color: #1e72ff;
    border-color: #116aff; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 2px rgba(68, 138, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #448AFF;
    border-color: #448AFF; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #116aff;
    border-color: #0462ff; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(68, 138, 255, 0.5); }

.btn-secondary {
  color: #6C7177;
  background-color: #fff;
  border-color: #ECEFF1; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #cfd6db; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 2px rgba(236, 239, 241, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #ECEFF1; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #6C7177;
    background-color: #e6e6e6;
    border-color: #c7d0d6; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(236, 239, 241, 0.5); }

.btn-success {
  color: #fff;
  background-color: #4CAF50;
  border-color: #4CAF50; }
  .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #4CAF50;
    border-color: #4CAF50; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.5); }

.btn-info {
  color: #fff;
  background-color: #03A9F4;
  border-color: #03A9F4; }
  .btn-info:hover {
    color: #fff;
    background-color: #038fce;
    border-color: #0286c2; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 2px rgba(3, 169, 244, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #03A9F4;
    border-color: #03A9F4; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0286c2;
    border-color: #027db5; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(3, 169, 244, 0.5); }

.btn-warning {
  color: #fff;
  background-color: #FFB300;
  border-color: #FFB300; }
  .btn-warning:hover {
    color: #212529;
    background-color: #d99800;
    border-color: #cc8f00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 2px rgba(255, 179, 0, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #FFB300;
    border-color: #FFB300; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc8f00;
    border-color: #bf8600; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(255, 179, 0, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #F44336;
  border-color: #F44336; }
  .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 2px rgba(244, 67, 54, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #F44336;
    border-color: #F44336; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(244, 67, 54, 0.5); }

.btn-warning:hover {
  color: #fff; }

.btn-outline-secondary {
  color: #6C7177;
  background-color: transparent;
  background-image: none;
  border-color: #6C7177;
  border-color: #ccd4dc; }
  .btn-outline-secondary:hover {
    color: #6C7177;
    background-color: #ccd4dc;
    border-color: #ccd4dc; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 2px rgba(108, 113, 119, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6C7177;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #ccd4dc;
    border-color: #ccd4dc; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(108, 113, 119, 0.5); }

.text-primary {
  color: #448AFF !important; }

.text-success {
  color: #4CAF50 !important; }

.text-info {
  color: #03A9F4 !important; }

.text-warning {
  color: #FFB300 !important; }

.text-danger {
  color: #F44336 !important; }

.pagination > .page-item > .page-link {
  color: inherit;
  background-color: rgba(240, 240, 240, 0.06);
  border-color: rgba(162, 162, 162, 0.16); }

.pagination > .page-item > .page-link:focus,
.pagination > .page-item > .page-link:hover {
  background-color: #448AFF;
  border-color: #448AFF;
  color: #fff; }

.pagination > .active > .page-link,
.pagination > .active > .page-link:focus,
.pagination > .active > .page-link:hover {
  background-color: #448AFF;
  border-color: #448AFF;
  color: #fff; }

.pagination > .disabled {
  cursor: not-allowed; }
  .pagination > .disabled > .page-link,
  .pagination > .disabled > .page-link:hover,
  .pagination > .disabled > .page-link:focus {
    color: #777;
    background-color: rgba(240, 240, 240, 0.06);
    border-color: rgba(162, 162, 162, 0.16); }

.tag {
  display: inline;
  padding: 2px 6px 3px;
  font-size: .625rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem; }

.tag-primary {
  background-color: #448AFF; }

.tag-info {
  background-color: #03A9F4; }

.tag-success {
  background-color: #4CAF50; }

.tag-warning {
  background-color: #FFB300; }

.tag-danger {
  background-color: #F44336; }

.alert-primary {
  color: #fff;
  background-color: #448AFF;
  border-color: #448AFF; }
  .alert-primary hr {
    border-top-color: #2b7aff; }
  .alert-primary .alert-link {
    color: #e6e6e6; }
  .alert-primary a, .alert-primary .alert-link {
    color: rgba(255, 255, 255, 0.58); }

.alert-success {
  color: #fff;
  background-color: #4CAF50;
  border-color: #4CAF50; }
  .alert-success hr {
    border-top-color: #449d48; }
  .alert-success .alert-link {
    color: #e6e6e6; }
  .alert-success a, .alert-success .alert-link {
    color: rgba(255, 255, 255, 0.58); }

.alert-info {
  color: #fff;
  background-color: #03A9F4;
  border-color: #03A9F4; }
  .alert-info hr {
    border-top-color: #0398db; }
  .alert-info .alert-link {
    color: #e6e6e6; }
  .alert-info a, .alert-info .alert-link {
    color: rgba(255, 255, 255, 0.58); }

.alert-warning {
  color: #fff;
  background-color: #FFB300;
  border-color: #FFB300; }
  .alert-warning hr {
    border-top-color: #e6a100; }
  .alert-warning .alert-link {
    color: #e6e6e6; }
  .alert-warning a, .alert-warning .alert-link {
    color: rgba(255, 255, 255, 0.58); }

.alert-danger {
  color: #fff;
  background-color: #F44336;
  border-color: #F44336; }
  .alert-danger hr {
    border-top-color: #f32c1e; }
  .alert-danger .alert-link {
    color: #e6e6e6; }
  .alert-danger a, .alert-danger .alert-link {
    color: rgba(255, 255, 255, 0.58); }

.alert-dismissable .close, .alert-dismissible .close {
  color: rgba(0, 0, 0, 0.75); }

.list-group .list-group-item {
  color: inherit;
  background-color: transparent;
  border-color: rgba(162, 162, 162, 0.12);
  padding: 1rem 1.5rem; }
  .list-group .list-group-item.active {
    color: #fff; }
  .list-group .list-group-item.disabled, .list-group .list-group-item.disabled:focus, .list-group .list-group-item.disabled:hover {
    background-color: rgba(162, 162, 162, 0.12);
    color: inherit; }
  .card > .list-group .list-group-item {
    border-top: 0; }

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
  background-color: transparent;
  color: #448AFF; }

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  background-color: #03A9F4;
  border-color: #03A9F4; }

.card {
  border-radius: 3px;
  border: 1px solid rgba(162, 162, 162, 0.16);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }
  .card .card-header {
    background: transparent;
    padding: 16px 24px;
    font-weight: 500;
    border-bottom: 1px solid rgba(162, 162, 162, 0.16); }
  .card .card-body {
    padding: 1.5rem; }
  .card .card-footer {
    background-color: rgba(162, 162, 162, 0.1);
    border-color: rgba(162, 162, 162, 0.1); }

.card-accordion .card-header a {
  color: inherit;
  text-decoration: none;
  font-size: 1rem; }
  .card-accordion .card-header a:hover, .card-accordion .card-header a:focus {
    color: #448AFF; }

.card-accordion .card .card-header {
  padding: 12px 24px; }

.card-accordion .card .card-title a {
  text-decoration: none; }

.card-default .card-header {
  background-color: rgba(162, 162, 162, 0.075); }

.card-inverse .text-muted {
  color: rgba(255, 255, 255, 0.5) !important; }

.well {
  border: 1px solid rgba(162, 162, 162, 0.16);
  background-color: #fff; }

.jumbotron {
  padding: 1.5rem 3rem;
  border: 1px solid rgba(162, 162, 162, 0.12);
  background-color: #fff; }
  .jumbotron h1 {
    font-size: 3.9375rem; }
  .jumbotron p {
    font-weight: 300;
    font-size: 1.3125rem;
    margin-bottom: 1rem; }
  @media only screen and (min-width: 768px) {
    .jumbotron {
      padding: 1.875rem 3.75rem; } }

.nav-tabs {
  padding-bottom: 1px;
  border-bottom: 0; }

.nav-tabs > .nav-item > .nav-link,
.nav-tabs.nav-justified > .nav-item > .nav-link {
  padding: 16px 24px;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  color: inherit; }
  .nav-tabs > .nav-item > .nav-link:hover, .nav-tabs > .nav-item > .nav-link:focus,
  .nav-tabs.nav-justified > .nav-item > .nav-link:hover,
  .nav-tabs.nav-justified > .nav-item > .nav-link:focus {
    border-bottom-color: rgba(162, 162, 162, 0.36);
    background-color: transparent;
    color: inherit; }

.nav-tabs.nav-justified.nav-item > .nav-link {
  margin-bottom: 2px;
  border-bottom-color: rgba(162, 162, 162, 0.16); }

.nav-tabs > .nav-item > .nav-link.active, .nav-tabs > .nav-item > .nav-link.active:hover, .nav-tabs > .nav-item > .nav-link.active:focus {
  color: #448AFF; }

.nav-tabs > .nav-item > .nav-link.active, .nav-tabs > .nav-item > .nav-link.active:focus, .nav-tabs > .nav-item > .nav-link.active:hover,
.nav-tabs.nav-justified > .nav-item > .active, .nav-tabs.nav-justified > .nav-item > .active:hover, .nav-tabs.nav-justified > .nav-item > .active:focus {
  border: 0;
  border-bottom: 2px solid;
  background-color: transparent; }

.tab-content {
  padding: 10px 20px; }

.nav-pills > .nav-item > .nav-link.active,
.nav-pills > .nav-item > .nav-link.active:focus,
.nav-pills > .nav-item > .nav-link.active:hover {
  background-color: #448AFF; }

.nav-pills > .nav-item + .nav-item {
  margin-left: 5px; }

.nav > .nav-item > .nav-link:focus, .nav > .nav-item > .nav-link:hover {
  background-color: rgba(162, 162, 162, 0.12); }

.form-control {
  height: calc(2rem + 2px);
  padding: .375rem .75rem;
  box-shadow: 0 0 0 #000 !important;
  font-size: inherit; }
  .form-control.form-control-lg {
    height: calc(2.875rem + 2px);
    padding: .5rem 1rem; }
  .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    outline: 0;
    background-color: #fff;
    color: #495057; }

.form-check-input {
  margin-right: .75rem; }

.input-group-prepend,
.input-group-append,
.input-group-text,
.custom-file-label,
.custom-file-label::after {
  height: calc(2rem + 2px);
  font-size: inherit; }

.custom-control-label {
  line-height: 1.75; }

@media only screen and (max-width: 1199px) {
  input[type="text"], input[type="email"], input[type="search"], input[type="password"] {
    -webkit-appearance: none; } }

.has-success .success-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4CAF50; }

.has-success .success-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.8);
  border-radius: .2rem; }

.was-validated .has-success .form-control:success, .has-success .form-control.is-success, .was-validated
.has-success .custom-select:success,
.has-success .custom-select.is-success {
  border-color: #4CAF50; }
  .was-validated .has-success .form-control:success:focus, .has-success .form-control.is-success:focus, .was-validated
  .has-success .custom-select:success:focus,
  .has-success .custom-select.is-success:focus {
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.25); }
  .was-validated .has-success .form-control:success ~ .success-feedback,
  .was-validated .has-success .form-control:success ~ .success-tooltip, .has-success .form-control.is-success ~ .success-feedback,
  .has-success .form-control.is-success ~ .success-tooltip, .was-validated
  .has-success .custom-select:success ~ .success-feedback,
  .was-validated
  .has-success .custom-select:success ~ .success-tooltip,
  .has-success .custom-select.is-success ~ .success-feedback,
  .has-success .custom-select.is-success ~ .success-tooltip {
    display: block; }

.was-validated .has-success .form-control-file:success ~ .success-feedback,
.was-validated .has-success .form-control-file:success ~ .success-tooltip, .has-success .form-control-file.is-success ~ .success-feedback,
.has-success .form-control-file.is-success ~ .success-tooltip {
  display: block; }

.was-validated .has-success .form-check-input:success ~ .form-check-label, .has-success .form-check-input.is-success ~ .form-check-label {
  color: #4CAF50; }

.was-validated .has-success .form-check-input:success ~ .success-feedback,
.was-validated .has-success .form-check-input:success ~ .success-tooltip, .has-success .form-check-input.is-success ~ .success-feedback,
.has-success .form-check-input.is-success ~ .success-tooltip {
  display: block; }

.was-validated .has-success .custom-control-input:success ~ .custom-control-label, .has-success .custom-control-input.is-success ~ .custom-control-label {
  color: #4CAF50; }
  .was-validated .has-success .custom-control-input:success ~ .custom-control-label::before, .has-success .custom-control-input.is-success ~ .custom-control-label::before {
    background-color: #a3d7a5; }

.was-validated .has-success .custom-control-input:success ~ .success-feedback,
.was-validated .has-success .custom-control-input:success ~ .success-tooltip, .has-success .custom-control-input.is-success ~ .success-feedback,
.has-success .custom-control-input.is-success ~ .success-tooltip {
  display: block; }

.was-validated .has-success .custom-control-input:success:checked ~ .custom-control-label::before, .has-success .custom-control-input.is-success:checked ~ .custom-control-label::before {
  background-color: #6ec071; }

.was-validated .has-success .custom-control-input:success:focus ~ .custom-control-label::before, .has-success .custom-control-input.is-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f1f2f3, 0 0 0 2px rgba(76, 175, 80, 0.25); }

.was-validated .has-success .custom-file-input:success ~ .custom-file-label, .has-success .custom-file-input.is-success ~ .custom-file-label {
  border-color: #4CAF50; }
  .was-validated .has-success .custom-file-input:success ~ .custom-file-label::before, .has-success .custom-file-input.is-success ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .has-success .custom-file-input:success ~ .success-feedback,
.was-validated .has-success .custom-file-input:success ~ .success-tooltip, .has-success .custom-file-input.is-success ~ .success-feedback,
.has-success .custom-file-input.is-success ~ .success-tooltip {
  display: block; }

.was-validated .has-success .custom-file-input:success:focus ~ .custom-file-label, .has-success .custom-file-input.is-success:focus ~ .custom-file-label {
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.25); }

.has-warning .warning-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #FFB300; }

.has-warning .warning-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(255, 179, 0, 0.8);
  border-radius: .2rem; }

.was-validated .has-warning .form-control:warning, .has-warning .form-control.is-warning, .was-validated
.has-warning .custom-select:warning,
.has-warning .custom-select.is-warning {
  border-color: #FFB300; }
  .was-validated .has-warning .form-control:warning:focus, .has-warning .form-control.is-warning:focus, .was-validated
  .has-warning .custom-select:warning:focus,
  .has-warning .custom-select.is-warning:focus {
    border-color: #FFB300;
    box-shadow: 0 0 0 2px rgba(255, 179, 0, 0.25); }
  .was-validated .has-warning .form-control:warning ~ .warning-feedback,
  .was-validated .has-warning .form-control:warning ~ .warning-tooltip, .has-warning .form-control.is-warning ~ .warning-feedback,
  .has-warning .form-control.is-warning ~ .warning-tooltip, .was-validated
  .has-warning .custom-select:warning ~ .warning-feedback,
  .was-validated
  .has-warning .custom-select:warning ~ .warning-tooltip,
  .has-warning .custom-select.is-warning ~ .warning-feedback,
  .has-warning .custom-select.is-warning ~ .warning-tooltip {
    display: block; }

.was-validated .has-warning .form-control-file:warning ~ .warning-feedback,
.was-validated .has-warning .form-control-file:warning ~ .warning-tooltip, .has-warning .form-control-file.is-warning ~ .warning-feedback,
.has-warning .form-control-file.is-warning ~ .warning-tooltip {
  display: block; }

.was-validated .has-warning .form-check-input:warning ~ .form-check-label, .has-warning .form-check-input.is-warning ~ .form-check-label {
  color: #FFB300; }

.was-validated .has-warning .form-check-input:warning ~ .warning-feedback,
.was-validated .has-warning .form-check-input:warning ~ .warning-tooltip, .has-warning .form-check-input.is-warning ~ .warning-feedback,
.has-warning .form-check-input.is-warning ~ .warning-tooltip {
  display: block; }

.was-validated .has-warning .custom-control-input:warning ~ .custom-control-label, .has-warning .custom-control-input.is-warning ~ .custom-control-label {
  color: #FFB300; }
  .was-validated .has-warning .custom-control-input:warning ~ .custom-control-label::before, .has-warning .custom-control-input.is-warning ~ .custom-control-label::before {
    background-color: #ffd980; }

.was-validated .has-warning .custom-control-input:warning ~ .warning-feedback,
.was-validated .has-warning .custom-control-input:warning ~ .warning-tooltip, .has-warning .custom-control-input.is-warning ~ .warning-feedback,
.has-warning .custom-control-input.is-warning ~ .warning-tooltip {
  display: block; }

.was-validated .has-warning .custom-control-input:warning:checked ~ .custom-control-label::before, .has-warning .custom-control-input.is-warning:checked ~ .custom-control-label::before {
  background-color: #ffc233; }

.was-validated .has-warning .custom-control-input:warning:focus ~ .custom-control-label::before, .has-warning .custom-control-input.is-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f1f2f3, 0 0 0 2px rgba(255, 179, 0, 0.25); }

.was-validated .has-warning .custom-file-input:warning ~ .custom-file-label, .has-warning .custom-file-input.is-warning ~ .custom-file-label {
  border-color: #FFB300; }
  .was-validated .has-warning .custom-file-input:warning ~ .custom-file-label::before, .has-warning .custom-file-input.is-warning ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .has-warning .custom-file-input:warning ~ .warning-feedback,
.was-validated .has-warning .custom-file-input:warning ~ .warning-tooltip, .has-warning .custom-file-input.is-warning ~ .warning-feedback,
.has-warning .custom-file-input.is-warning ~ .warning-tooltip {
  display: block; }

.was-validated .has-warning .custom-file-input:warning:focus ~ .custom-file-label, .has-warning .custom-file-input.is-warning:focus ~ .custom-file-label {
  box-shadow: 0 0 0 2px rgba(255, 179, 0, 0.25); }

.has-danger .danger-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #F44336; }

.has-danger .danger-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.8);
  border-radius: .2rem; }

.was-validated .has-danger .form-control:danger, .has-danger .form-control.is-danger, .was-validated
.has-danger .custom-select:danger,
.has-danger .custom-select.is-danger {
  border-color: #F44336; }
  .was-validated .has-danger .form-control:danger:focus, .has-danger .form-control.is-danger:focus, .was-validated
  .has-danger .custom-select:danger:focus,
  .has-danger .custom-select.is-danger:focus {
    border-color: #F44336;
    box-shadow: 0 0 0 2px rgba(244, 67, 54, 0.25); }
  .was-validated .has-danger .form-control:danger ~ .danger-feedback,
  .was-validated .has-danger .form-control:danger ~ .danger-tooltip, .has-danger .form-control.is-danger ~ .danger-feedback,
  .has-danger .form-control.is-danger ~ .danger-tooltip, .was-validated
  .has-danger .custom-select:danger ~ .danger-feedback,
  .was-validated
  .has-danger .custom-select:danger ~ .danger-tooltip,
  .has-danger .custom-select.is-danger ~ .danger-feedback,
  .has-danger .custom-select.is-danger ~ .danger-tooltip {
    display: block; }

.was-validated .has-danger .form-control-file:danger ~ .danger-feedback,
.was-validated .has-danger .form-control-file:danger ~ .danger-tooltip, .has-danger .form-control-file.is-danger ~ .danger-feedback,
.has-danger .form-control-file.is-danger ~ .danger-tooltip {
  display: block; }

.was-validated .has-danger .form-check-input:danger ~ .form-check-label, .has-danger .form-check-input.is-danger ~ .form-check-label {
  color: #F44336; }

.was-validated .has-danger .form-check-input:danger ~ .danger-feedback,
.was-validated .has-danger .form-check-input:danger ~ .danger-tooltip, .has-danger .form-check-input.is-danger ~ .danger-feedback,
.has-danger .form-check-input.is-danger ~ .danger-tooltip {
  display: block; }

.was-validated .has-danger .custom-control-input:danger ~ .custom-control-label, .has-danger .custom-control-input.is-danger ~ .custom-control-label {
  color: #F44336; }
  .was-validated .has-danger .custom-control-input:danger ~ .custom-control-label::before, .has-danger .custom-control-input.is-danger ~ .custom-control-label::before {
    background-color: #fbb4af; }

.was-validated .has-danger .custom-control-input:danger ~ .danger-feedback,
.was-validated .has-danger .custom-control-input:danger ~ .danger-tooltip, .has-danger .custom-control-input.is-danger ~ .danger-feedback,
.has-danger .custom-control-input.is-danger ~ .danger-tooltip {
  display: block; }

.was-validated .has-danger .custom-control-input:danger:checked ~ .custom-control-label::before, .has-danger .custom-control-input.is-danger:checked ~ .custom-control-label::before {
  background-color: #f77066; }

.was-validated .has-danger .custom-control-input:danger:focus ~ .custom-control-label::before, .has-danger .custom-control-input.is-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f1f2f3, 0 0 0 2px rgba(244, 67, 54, 0.25); }

.was-validated .has-danger .custom-file-input:danger ~ .custom-file-label, .has-danger .custom-file-input.is-danger ~ .custom-file-label {
  border-color: #F44336; }
  .was-validated .has-danger .custom-file-input:danger ~ .custom-file-label::before, .has-danger .custom-file-input.is-danger ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .has-danger .custom-file-input:danger ~ .danger-feedback,
.was-validated .has-danger .custom-file-input:danger ~ .danger-tooltip, .has-danger .custom-file-input.is-danger ~ .danger-feedback,
.has-danger .custom-file-input.is-danger ~ .danger-tooltip {
  display: block; }

.was-validated .has-danger .custom-file-input:danger:focus ~ .custom-file-label, .has-danger .custom-file-input.is-danger:focus ~ .custom-file-label {
  box-shadow: 0 0 0 2px rgba(244, 67, 54, 0.25); }

.input-sm,
select.input-sm {
  height: 31px; }

legend {
  border-bottom: 1px solid rgba(162, 162, 162, 0.25);
  padding-bottom: 1rem; }

fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed rgba(162, 162, 162, 0.25);
  margin-bottom: 20px; }
  fieldset.last-child, fieldset:last-child {
    border-bottom: 0; }
  fieldset .form-group {
    margin-bottom: 0; }

@media only screen and (max-width: 991px) {
  input[type="text"], input[type="email"], input[type="search"], input[type="password"] {
    -webkit-appearance: none; } }

.table {
  font-weight: 400;
  margin-bottom: 2rem; }
  .table > thead > tr > th {
    border-bottom-width: 1px;
    border-color: rgba(162, 162, 162, 0.16);
    position: relative;
    vertical-align: bottom;
    text-overflow: ellipsis;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    height: 48px;
    font-size: 12px;
    padding: 0 18px 0 18px !important;
    padding-bottom: 8px !important; }
  .table > tbody > tr > td {
    position: relative;
    vertical-align: middle;
    height: 48px !important;
    padding: 14px 18px;
    border-top: 1px solid rgba(0, 0, 0, 0.045); }
  .table > tbody + tbody {
    border-bottom-width: 1px; }
  .table td, .table th {
    border-top: 0; }

.table-hover > tbody > tr:hover {
  background-color: rgba(162, 162, 162, 0.06); }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: rgba(162, 162, 162, 0.06); }

.table-bordered {
  border-color: rgba(162, 162, 162, 0.26); }
  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td {
    border-bottom-width: 1px; }
  .table-bordered > tbody > tr > td,
  .table-bordered > tbody > tr > th,
  .table-bordered > tfoot > tr > td,
  .table-bordered > tfoot > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > thead > tr > th {
    border-color: rgba(162, 162, 162, 0.26); }

.card > .table-bordered, .card > .table-responsive > .table-bordered {
  border: 0; }

.card .table-bordered td:first-child, .card .table-bordered th:first-child {
  border-left: 0; }

.card .table-bordered td:last-child, .card .table-bordered th:last-child {
  border-right: 0; }

.card > .table > tbody:first-child > tr:first-child td,
.card > .table > thead:first-child > tr:first-child th {
  border-top: 0; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #CFD8DC; }

.table-hover .table-active:hover {
  background-color: #c0ccd1; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #c0ccd1; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #4CAF50; }

.table-hover .table-success:hover {
  background-color: #449d48; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #449d48; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #03A9F4; }

.table-hover .table-info:hover {
  background-color: #0398db; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #0398db; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #FFB300; }

.table-hover .table-warning:hover {
  background-color: #e6a100; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #e6a100; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #F44336; }

.table-hover .table-danger:hover {
  background-color: #f32c1e; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f32c1e; }

tr.active, tr.success, tr.info, tr.warning, tr.danger {
  color: rgba(0, 0, 0, 0.67); }
  tr.active > td, tr.success > td, tr.info > td, tr.warning > td, tr.danger > td {
    border-top: 0 !important; }

.progress {
  box-shadow: 0 0 0 #000;
  border-radius: 3px;
  border: 1px solid rgba(240, 240, 240, 0.1);
  background-color: rgba(162, 162, 162, 0.26);
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: inherit; }
  .progress .progress-bar {
    height: 100%;
    box-shadow: 0 0 0 #000;
    line-height: 1.4; }
  .progress.progress-sm {
    height: 16px; }
  .progress.progress-xs {
    height: 8px;
    border-radius: 0;
    border: 0; }

.progress-bar {
  background-color: #448AFF; }

.progress-bar-info {
  background-color: #03A9F4; }

.progress-bar-success {
  background-color: #4CAF50; }

.progress-bar-warning {
  background-color: #FFB300; }

.progress-bar-danger {
  background-color: #F44336; }

.popover {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014);
  border-color: #ECEFF1;
  border-radius: 3px;
  color: #6C7177;
  font-size: inherit; }
  .popover .popover-title {
    margin: 0;
    line-height: 1;
    font-size: 1.6rem;
    font-weight: 500; }
  .popover .popover-header {
    margin: 0;
    line-height: 1.2; }

.tooltip {
  font-size: inherit; }

.carousel .carousel-indicators {
  bottom: 0; }

.carousel .carousel-control.left, .carousel .carousel-control.right {
  background-image: none; }

.carousel .carousel-control em {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px; }

.dropdown-menu {
  border: 1px solid #ECEFF1;
  border-radius: 1px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-size: inherit; }
  .dropdown-menu .dropdown-item {
    padding: 0 14px;
    line-height: 48px;
    display: block;
    color: #263238; }
    .dropdown-menu .dropdown-item:hover {
      background: #ECEFF1; }
    .dropdown-menu .dropdown-item.active {
      color: #fff;
      background-color: #007bff; }
  .dropdown-menu .dropdown-item > em {
    margin-right: 10px; }
  .dropdown-menu .dropdown-divider {
    margin: .5625rem 0; }

.dropdown-header {
  margin: 0;
  color: #a1a2a3;
  padding: 0 14px; }

.navbar-default .navbar-nav .show .dropdown-menu > li > a {
  color: #666; }
  .navbar-default .navbar-nav .show .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .show .dropdown-menu > li > a:focus {
    color: #222; }

.navbar,
.navbar .dropdown-menu {
  -webkit-filter: none !important;
          filter: none !important; }

.badge {
  width: 20px;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  border-radius: 50%;
  color: #fff; }

.has-badge {
  position: relative; }
  .has-badge .badge {
    position: absolute;
    top: 18%;
    right: 4px; }
  .has-badge.dropdown-toggle::after {
    display: none; }

/**
 * Animations
 */
.rag-fadeIn-enter {
  opacity: 0.01; }
  .rag-fadeIn-enter-active {
    opacity: 1;
    transition: all 300ms ease-out; }

.rag-fadeIn-leave {
  display: none; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }
  .animated.infinite {
    animation-iteration-count: infinite; }
  .animated.hinge {
    animation-duration: 2s; }

@keyframes fadeInRightShort {
  0% {
    opacity: 0;
    transform: translate3d(18px, 0, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInRightShort {
  animation-name: fadeInRightShort; }

@keyframes fadeOutRightShort {
  from {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(18px, 0, 0); } }

.fadeOutRightShort {
  animation-name: fadeOutRightShort; }

@keyframes fadeInLeftShort {
  0% {
    opacity: 0;
    transform: translate3d(-18px, 0, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInLeftShort {
  animation-name: fadeInLeftShort; }

@keyframes fadeOutLeftShort {
  from {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(-18px, 0, 0); } }

.fadeOutLeftShort {
  animation-name: fadeOutLeftShort; }

@keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(0, -20px, 0); } }

.fadeOutUpShort {
  animation-name: fadeOutUpShort; }

@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInUpShort {
  animation-name: fadeInUpShort; }

@keyframes fadeInDownShort {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInDownShort {
  animation-name: fadeInDownShort; }

@keyframes fadeOutDownShort {
  from {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(0, 20px, 0); } }

.fadeOutDownShort {
  animation-name: fadeOutDownShort; }

@keyframes zoomInShort {
  0% {
    opacity: 0;
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

.zoomInShort {
  animation-name: zoomInShort; }

@keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

.zoomBack {
  animation-name: zoomBack; }

/**
 * Buttons Extras
 */
.input-group-btn > .btn {
  border-color: rgba(0, 0, 0, 0.26); }

.btn-inverse {
  color: #fff;
  background-color: #37474F;
  border-color: #37474F; }
  .btn-inverse:hover {
    color: #fff;
    background-color: #273338;
    border-color: #222c31; }
  .btn-inverse:focus, .btn-inverse.focus {
    box-shadow: 0 0 0 2px rgba(55, 71, 79, 0.5); }
  .btn-inverse.disabled, .btn-inverse:disabled {
    color: #fff;
    background-color: #37474F;
    border-color: #37474F; }
  .btn-inverse:not(:disabled):not(.disabled):active, .btn-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-inverse.dropdown-toggle {
    color: #fff;
    background-color: #222c31;
    border-color: #1d2529; }
    .btn-inverse:not(:disabled):not(.disabled):active:focus, .btn-inverse:not(:disabled):not(.disabled).active:focus,
    .show > .btn-inverse.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(55, 71, 79, 0.5); }

.btn-flat {
  border-color: transparent !important;
  border-radius: 2px;
  background-color: transparent;
  text-transform: uppercase; }
  .btn-flat:active, .btn-flat.active {
    box-shadow: 0 0 0 #000; }
  .btn-flat.btn-orange {
    color: #FF8700; }
    .btn-flat.btn-orange:hover, .btn-flat.btn-orange:focus, .btn-flat.btn-orange:active {
      color: #fff; }
    .btn-flat.btn-orange:focus {
      background-color: #FF8700; }
    .btn-flat.btn-orange[disabled], .btn-flat.btn-orange.disabled {
      color: rgba(255, 135, 0, 0.75) !important; }
  .btn-flat.btn-primary {
    color: #448AFF; }
    .btn-flat.btn-primary:hover, .btn-flat.btn-primary:focus, .btn-flat.btn-primary:active {
      color: #fff; }
    .btn-flat.btn-primary:focus {
      background-color: #448AFF; }
    .btn-flat.btn-primary[disabled], .btn-flat.btn-primary.disabled {
      color: rgba(68, 138, 255, 0.75) !important; }
  .btn-flat.btn-success {
    color: #4CAF50; }
    .btn-flat.btn-success:hover, .btn-flat.btn-success:focus, .btn-flat.btn-success:active {
      color: #fff; }
    .btn-flat.btn-success:focus {
      background-color: #4CAF50; }
    .btn-flat.btn-success[disabled], .btn-flat.btn-success.disabled {
      color: rgba(76, 175, 80, 0.75) !important; }
  .btn-flat.btn-danger {
    color: #F44336; }
    .btn-flat.btn-danger:hover, .btn-flat.btn-danger:focus, .btn-flat.btn-danger:active {
      color: #fff; }
    .btn-flat.btn-danger:focus {
      background-color: #F44336; }
    .btn-flat.btn-danger[disabled], .btn-flat.btn-danger.disabled {
      color: rgba(244, 67, 54, 0.75) !important; }
  .btn-flat.btn-warning {
    color: #FFB300; }
    .btn-flat.btn-warning:hover, .btn-flat.btn-warning:focus, .btn-flat.btn-warning:active {
      color: #fff; }
    .btn-flat.btn-warning:focus {
      background-color: #FFB300; }
    .btn-flat.btn-warning[disabled], .btn-flat.btn-warning.disabled {
      color: rgba(255, 179, 0, 0.75) !important; }
  .btn-flat.btn-info {
    color: #03A9F4; }
    .btn-flat.btn-info:hover, .btn-flat.btn-info:focus, .btn-flat.btn-info:active {
      color: #fff; }
    .btn-flat.btn-info:focus {
      background-color: #03A9F4; }
    .btn-flat.btn-info[disabled], .btn-flat.btn-info.disabled {
      color: rgba(3, 169, 244, 0.75) !important; }
  .btn-flat.btn-inverse {
    color: #37474F; }
    .btn-flat.btn-inverse:hover, .btn-flat.btn-inverse:focus, .btn-flat.btn-inverse:active {
      color: #fff; }
    .btn-flat.btn-inverse:focus {
      background-color: #37474F; }
    .btn-flat.btn-inverse[disabled], .btn-flat.btn-inverse.disabled {
      color: rgba(55, 71, 79, 0.75) !important; }
  .btn-flat.text-white {
    color: #fff !important; }
  .btn-flat[disabled], .btn-flat.disabled {
    background-color: transparent !important; }
  .btn-flat.btn-flat-icon {
    border-radius: 50%;
    font-size: 24px;
    height: 32px;
    width: 32px;
    padding: 0;
    overflow: hidden;
    color: inherit !important; }
    .btn-flat.btn-flat-icon > em {
      line-height: 32px; }
    .btn-flat.btn-flat-icon:hover, .btn-flat.btn-flat-icon:focus, .btn-flat.btn-flat-icon:active {
      background-color: rgba(158, 158, 158, 0.2) !important; }

.btn-raised {
  border: 0;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014); }
  .btn-raised:hover, .btn-raised:active, .btn-raised.active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4) !important; }

.float-right > .btn-flat-icon {
  margin-top: -4px; }

.btn-circle {
  width: 46px;
  height: 46px;
  overflow: hidden;
  border-radius: 50% !important;
  line-height: 46px;
  padding: 0;
  text-align: center; }

.btn-outline {
  background-color: transparent;
  border-color: #fff; }
  .btn-outline:hover, .btn-outline:focus {
    background-color: #fff;
    color: #448AFF; }

.btn-xl {
  padding: 20px 16px;
  font-size: 18px; }

.btn-square {
  border-radius: 0; }

.btn-pill-left, .btn-oval {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 18px; }

.btn-pill-right, .btn-oval {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-right: 18px; }

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0; }

.btn-label {
  position: relative;
  background: transparent;
  display: inline-block;
  padding: 6px 16px;
  left: -16px;
  border-radius: 3px 0 0 3px; }
  .btn-label.btn-label-right {
    left: auto;
    right: -16px;
    border-radius: 0 3px 3px 0; }
  .btn-label:after {
    content: "";
    position: absolute;
    top: 5px;
    bottom: 5px;
    right: 0;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.1); }
  .btn-label.btn-label-right {
    text-indent: -2px; }
    .btn-label.btn-label-right:after {
      left: 0;
      right: auto; }

.btn-lg .btn-label {
  padding: 10px 20px;
  left: -20px;
  border-radius: 5px 0 0 5px; }
  .btn-lg .btn-label.btn-label-right {
    left: auto;
    right: -20px;
    border-radius: 0 5px 5px 0; }

.btn-sm .btn-label {
  padding: 1px 5px;
  left: -5px;
  border-radius: 2px 0 0 2px; }
  .btn-sm .btn-label.btn-label-right {
    left: auto;
    right: -5px;
    border-radius: 0 2px 2px 0; }

.btn-fw {
  min-width: 80px; }
  .btn-fw.btn-sm {
    min-width: 40px; }
  .btn-fw.btn-md {
    min-width: 60px; }
  .btn-fw.btn-lg {
    min-width: 140px; }

.pagination-rounded .page-item > .page-link {
  border-radius: 50% !important;
  margin-right: 4px;
  margin-left: 4px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  padding: 0;
  text-align: center; }
  .pagination-rounded .page-item > .page-link > span {
    position: relative;
    top: -1px; }

.pagination-rounded.pagination-lg .page-item > .page-link {
  width: 44px;
  height: 44px;
  line-height: 44px; }

.float-left.btn-group,
.float-right.btn-group {
  position: relative;
  z-index: 1; }

/**
 * Dropdowns Extras
 */
.dropdown-lg > .dropdown-menu {
  min-width: 200px; }

.dropdown-list > .dropdown-menu {
  padding: 0;
  min-width: 220px; }

.dropdown-list .list-group {
  margin: 0; }

.dropdown-list .list-group-item {
  border-radius: 0;
  border-left: 0;
  border-right: 0; }
  .dropdown-list .list-group-item:first-child {
    border-top: 0; }
  .dropdown-list .list-group-item:last-child {
    border-bottom: 0; }

.dropdown > a {
  position: relative; }
  .dropdown > a > .label, .dropdown > a > .tag {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px; }
    @media only screen and (min-width: 768px) {
      .dropdown > a > .label, .dropdown > a > .tag {
        top: 10px; } }

.dropdown-menu-header {
  padding: 10px 15px;
  background-color: #fafafa;
  border-bottom: 1px solid #ddd; }

/**
 * Rows - Extras
 */
.row {
  margin-right: -4px;
  margin-left: -4px; }
  .row .col-lg-1, .row .col-lg-2, .row .col-lg-3, .row .col-lg-4, .row .col-lg-5, .row .col-lg-6, .row .col-lg-7, .row .col-lg-8, .row .col-lg-9, .row .col-lg-10, .row .col-lg-11, .row .col-lg-12,
  .row .col-xl-1, .row .col-xl-2, .row .col-xl-3, .row .col-xl-4, .row .col-xl-5, .row .col-xl-6, .row .col-xl-7, .row .col-xl-8, .row .col-xl-9, .row .col-xl-10, .row .col-xl-11, .row .col-xl-12,
  .row .col-md-1, .row .col-md-2, .row .col-md-3, .row .col-md-4, .row .col-md-5, .row .col-md-6, .row .col-md-7, .row .col-md-8, .row .col-md-9, .row .col-md-10, .row .col-md-11, .row .col-md-12,
  .row .col-sm-1, .row .col-sm-2, .row .col-sm-3, .row .col-sm-4, .row .col-sm-5, .row .col-sm-6, .row .col-sm-7, .row .col-sm-8, .row .col-sm-9, .row .col-sm-10, .row .col-sm-11, .row .col-sm-12,
  .row .col-xs-1, .row .col-xs-2, .row .col-xs-3, .row .col-xs-4, .row .col-xs-5, .row .col-xs-6, .row .col-xs-7, .row .col-xs-8, .row .col-xs-9, .row .col-xs-10, .row .col-xs-11, .row .col-xs-12,
  .row .col-1, .row .col-2, .row .col-3, .row .col-4, .row .col-5, .row .col-6, .row .col-7, .row .col-8, .row .col-9, .row .col-10, .row .col-11, .row .col-12 {
    padding-right: 4px;
    padding-left: 4px; }
  @media only screen and (min-width: 480px) {
    .row {
      margin-right: -8px;
      margin-left: -8px; }
      .row .col-lg-1, .row .col-lg-2, .row .col-lg-3, .row .col-lg-4, .row .col-lg-5, .row .col-lg-6, .row .col-lg-7, .row .col-lg-8, .row .col-lg-9, .row .col-lg-10, .row .col-lg-11, .row .col-lg-12,
      .row .col-xl-1, .row .col-xl-2, .row .col-xl-3, .row .col-xl-4, .row .col-xl-5, .row .col-xl-6, .row .col-xl-7, .row .col-xl-8, .row .col-xl-9, .row .col-xl-10, .row .col-xl-11, .row .col-xl-12,
      .row .col-md-1, .row .col-md-2, .row .col-md-3, .row .col-md-4, .row .col-md-5, .row .col-md-6, .row .col-md-7, .row .col-md-8, .row .col-md-9, .row .col-md-10, .row .col-md-11, .row .col-md-12,
      .row .col-sm-1, .row .col-sm-2, .row .col-sm-3, .row .col-sm-4, .row .col-sm-5, .row .col-sm-6, .row .col-sm-7, .row .col-sm-8, .row .col-sm-9, .row .col-sm-10, .row .col-sm-11, .row .col-sm-12,
      .row .col-xs-1, .row .col-xs-2, .row .col-xs-3, .row .col-xs-4, .row .col-xs-5, .row .col-xs-6, .row .col-xs-7, .row .col-xs-8, .row .col-xs-9, .row .col-xs-10, .row .col-xs-11, .row .col-xs-12,
      .row .col-1, .row .col-2, .row .col-3, .row .col-4, .row .col-5, .row .col-6, .row .col-7, .row .col-8, .row .col-9, .row .col-10, .row .col-11, .row .col-12 {
        padding-right: 8px;
        padding-left: 8px; } }
  @media only screen and (min-width: 992px) {
    .row {
      margin-right: -12px;
      margin-left: -12px; }
      .row .col-lg-1, .row .col-lg-2, .row .col-lg-3, .row .col-lg-4, .row .col-lg-5, .row .col-lg-6, .row .col-lg-7, .row .col-lg-8, .row .col-lg-9, .row .col-lg-10, .row .col-lg-11, .row .col-lg-12,
      .row .col-xl-1, .row .col-xl-2, .row .col-xl-3, .row .col-xl-4, .row .col-xl-5, .row .col-xl-6, .row .col-xl-7, .row .col-xl-8, .row .col-xl-9, .row .col-xl-10, .row .col-xl-11, .row .col-xl-12,
      .row .col-md-1, .row .col-md-2, .row .col-md-3, .row .col-md-4, .row .col-md-5, .row .col-md-6, .row .col-md-7, .row .col-md-8, .row .col-md-9, .row .col-md-10, .row .col-md-11, .row .col-md-12,
      .row .col-sm-1, .row .col-sm-2, .row .col-sm-3, .row .col-sm-4, .row .col-sm-5, .row .col-sm-6, .row .col-sm-7, .row .col-sm-8, .row .col-sm-9, .row .col-sm-10, .row .col-sm-11, .row .col-sm-12,
      .row .col-xs-1, .row .col-xs-2, .row .col-xs-3, .row .col-xs-4, .row .col-xs-5, .row .col-xs-6, .row .col-xs-7, .row .col-xs-8, .row .col-xs-9, .row .col-xs-10, .row .col-xs-11, .row .col-xs-12,
      .row .col-1, .row .col-2, .row .col-3, .row .col-4, .row .col-5, .row .col-6, .row .col-7, .row .col-8, .row .col-9, .row .col-10, .row .col-11, .row .col-12 {
        padding-right: 12px;
        padding-left: 12px; } }

.row-table {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0; }
  .row-table > [class*="col-"] {
    display: table-cell;
    float: none;
    table-layout: fixed;
    vertical-align: middle; }

.row-flush > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

.t-grid .t-row {
  width: 100%;
  display: table;
  table-layout: fixed; }

.t-grid .t-col, .t-grid .t-cell {
  display: table-cell; }

/**
 * Modals
 */
.modal-header,
.modal-footer {
  border-color: rgba(162, 162, 162, 0.12); }

.modal.modal-right .modal-dialog,
.modal.modal-left .modal-dialog,
.modal.modal-bottom .modal-dialog,
.modal.modal-top .modal-dialog {
  position: absolute;
  margin: 0;
  max-width: none;
  transition-transform: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .modal.modal-right .modal-dialog > .modal-content,
  .modal.modal-left .modal-dialog > .modal-content,
  .modal.modal-bottom .modal-dialog > .modal-content,
  .modal.modal-top .modal-dialog > .modal-content {
    border-radius: 0;
    border: 0; }

.modal.modal-right.fade.show .modal-dialog,
.modal.modal-left.fade.show .modal-dialog,
.modal.modal-bottom.fade.show .modal-dialog,
.modal.modal-top.fade.show .modal-dialog {
  transform: translate(0, 0); }

.modal.fade.modal-right .modal-dialog,
.modal.fade.modal-left .modal-dialog,
.modal.fade.modal-bottom .modal-dialog,
.modal.fade.modal-top .modal-dialog {
  transition-transform: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.modal.modal-right .modal-dialog {
  right: 0;
  bottom: 0;
  top: 0;
  width: 240px; }
  .modal.modal-right .modal-dialog > .modal-content {
    min-height: 100%; }

.modal.modal-right.modal-auto-size .modal-dialog {
  width: 80%; }
  @media only screen and (min-width: 768px) {
    .modal.modal-right.modal-auto-size .modal-dialog {
      width: 50%; } }

.modal.modal-right.fade .modal-dialog {
  transform: translate(100%, 0); }

.modal.modal-left .modal-dialog {
  left: 0;
  bottom: 0;
  top: 0;
  width: 240px; }
  .modal.modal-left .modal-dialog > .modal-content {
    min-height: 100%; }

.modal.modal-left.modal-auto-size .modal-dialog {
  width: 80%; }
  @media only screen and (min-width: 768px) {
    .modal.modal-left.modal-auto-size .modal-dialog {
      width: 50%; } }

.modal.modal-left.fade .modal-dialog {
  transform: translate(-100%, 0); }

.modal.modal-top .modal-dialog {
  right: 0;
  left: 0;
  top: 0;
  width: 100%; }
  .modal.modal-top .modal-dialog > .modal-content {
    width: 100%; }

.modal.modal-top.fade .modal-dialog {
  transform: translate(0, -100%); }

.modal.modal-bottom .modal-dialog {
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%; }
  .modal.modal-bottom .modal-dialog > .modal-content {
    width: 100%; }

.modal.modal-bottom.fade .modal-dialog {
  transform: translate(0, 100%); }

.modal-content {
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084); }

.modal-backdrop {
  opacity: 0;
  will-change: opacity; }
  .modal-backdrop.show {
    transition: opacity .28s ease-in-out; }

.modal-backdrop-light .modal-backdrop {
  background-color: #fff; }
  .modal-backdrop-light .modal-backdrop.show {
    opacity: .9; }

.modal-backdrop-soft .modal-backdrop.show {
  opacity: .3; }

.modal-backdrop {
  position: fixed;
  bottom: 0; }

/**
 * Themes
 */
.theme-1 .layout-container > aside {
  background-color: #fff;
  color: #6C7177; }
  .theme-1 .layout-container > aside > .sidebar-header {
    background-position: center;
    background-repeat: no-repeat;
    background-color: #7c3088; }

.theme-1 .layout-container > header {
  background-color: #7c3088;
  color: #fff; }

.theme-2 .layout-container > aside {
  background-color: #F1F2F3;
  color: #fff; }
  .theme-2 .layout-container > aside > .sidebar-header {
    background-position: center;
    background-repeat: no-repeat;
    background-color: #7c3088; }

.theme-2 .layout-container > header {
  background-color: #1976D2;
  color: #fff; }

.theme-2 .layout-container > main {
  background-color: #ECEFF1; }

.theme-3 {
  background-color: #2f3c43; }
  .theme-3 .layout-container > aside {
    background-color: #F1F2F3;
    color: #fff; }
    .theme-3 .layout-container > aside > .sidebar-header {
      background-position: center;
      background-repeat: no-repeat;
      background-color: #7c3088; }
  .theme-3 .layout-container > header {
    background-color: #37474F;
    color: #fff; }
  .theme-3 .layout-container > main {
    background-color: #2f3c43;
    color: #f1f1f1; }
    .theme-3 .layout-container > main .bg-white:not([class*='bg-']),
    .theme-3 .layout-container > main .card:not([class*='bg-']),
    .theme-3 .layout-container > main .cardbox:not([class*='bg-']),
    .theme-3 .layout-container > main .card-default > .card-header:not([class*='bg-']) {
      background-color: #35444c;
      color: inherit; }
    .theme-3 .layout-container > main .bg-white {
      background-color: #35444c !important;
      color: inherit; }
    .theme-3 .layout-container > main .well, .theme-3 .layout-container > main .jumbotron, .theme-3 .layout-container > main .breadcrumb, .theme-3 .layout-container > main .modal-content {
      background-color: #35444c;
      color: inherit; }
    .theme-3 .layout-container > main .btn-secondary {
      color: #fff;
      background-color: #37474F;
      border-color: #2d3940; }
      .theme-3 .layout-container > main .btn-secondary:hover {
        color: #fff;
        background-color: #273338;
        border-color: #181e22; }
      .theme-3 .layout-container > main .btn-secondary:focus, .theme-3 .layout-container > main .btn-secondary.focus {
        box-shadow: 0 0 0 2px rgba(45, 57, 64, 0.5); }
      .theme-3 .layout-container > main .btn-secondary.disabled, .theme-3 .layout-container > main .btn-secondary:disabled {
        color: #fff;
        background-color: #37474F;
        border-color: #2d3940; }
      .theme-3 .layout-container > main .btn-secondary:not(:disabled):not(.disabled):active, .theme-3 .layout-container > main .btn-secondary:not(:disabled):not(.disabled).active,
      .show > .theme-3 .layout-container > main .btn-secondary.dropdown-toggle {
        color: #fff;
        background-color: #222c31;
        border-color: #12181a; }
        .theme-3 .layout-container > main .btn-secondary:not(:disabled):not(.disabled):active:focus, .theme-3 .layout-container > main .btn-secondary:not(:disabled):not(.disabled).active:focus,
        .show > .theme-3 .layout-container > main .btn-secondary.dropdown-toggle:focus {
          box-shadow: 0 0 0 2px rgba(45, 57, 64, 0.5); }
    .theme-3 .layout-container > main .show > .dropdown-toggle.btn-secondary, .theme-3 .layout-container > main .show > .dropdown-toggle.btn-secondary:hover, .theme-3 .layout-container > main .show > .dropdown-toggle.btn-secondary:active, .theme-3 .layout-container > main .show > .dropdown-toggle.btn-secondary.active, .theme-3 .layout-container > main .show > .dropdown-toggle.btn-secondary:focus {
      color: #fff;
      background-color: #37474F;
      border-color: #2d3940; }
    .theme-3 .layout-container > main .form-control {
      color: inherit;
      background-color: rgba(240, 240, 240, 0.1);
      border-color: rgba(240, 240, 240, 0.12); }
      .theme-3 .layout-container > main .form-control:focus {
        border-color: rgba(240, 240, 240, 0.36); }
    .theme-3 .layout-container > main .form-control[disabled],
    .theme-3 .layout-container > main .form-control[readonly],
    .theme-3 .layout-container > main fieldset[disabled] .form-control {
      background-color: rgba(240, 240, 240, 0.1); }
    .theme-3 .layout-container > main select:not([multiple]) option {
      color: #6C7177; }
    .theme-3 .layout-container > main .input-group-addon,
    .theme-3 .layout-container > main .input-group-button {
      background-color: transparent;
      border: 1px solid rgba(162, 162, 162, 0.26);
      color: inherit; }
    .theme-3 .layout-container > main .nav-tabs > .nav-item > .nav-link.active {
      color: #fff; }
    .theme-3 .layout-container > main .note-editor .note-editing-area .note-editable {
      background-color: rgba(240, 240, 240, 0.1); }
    .theme-3 .layout-container > main .photo {
      background-color: #37474F; }
  .theme-3 .sidebar-header-logo > svg, .theme-3 .sidebar-header-logo > em,
  .theme-3 .nav-icon > svg,
  .theme-3 .nav-icon > em {
    color: #448AFF !important;
    fill: #4CAF50 !important; }

.theme-4 .layout-container > aside {
  background-color: #F1F2F3;
  color: #fff; }
  .theme-4 .layout-container > aside > .sidebar-header {
    background-position: center;
    background-repeat: no-repeat;
    background-color: #7c3088; }

.theme-4 .layout-container > header {
  background-color: #4CAF50;
  color: #fff; }

.theme-4 .layout-container > main {
  background-color: #ECEFF1; }

.theme-4 .sidebar-header-logo > svg, .theme-4 .sidebar-header-logo > em,
.theme-4 .nav-icon > svg,
.theme-4 .nav-icon > em {
  color: #4CAF50 !important;
  fill: #a3d7a5 !important; }

.theme-5 .layout-container > aside {
  background-color: #F1F2F3;
  color: #fff; }
  .theme-5 .layout-container > aside > .sidebar-header {
    background-position: center;
    background-repeat: no-repeat;
    background-color: #7c3088; }

.theme-5 .layout-container > header {
  background-color: #03A9F4;
  color: #fff; }

.theme-5 .layout-container > main {
  background-color: #ECEFF1; }

.theme-5 .sidebar-header-logo > svg, .theme-5 .sidebar-header-logo > em,
.theme-5 .nav-icon > svg,
.theme-5 .nav-icon > em {
  color: #03A9F4 !important;
  fill: #79d4fd !important; }

.theme-6 .layout-container > aside {
  background-color: #F1F2F3;
  color: #fff; }
  .theme-6 .layout-container > aside > .sidebar-header {
    background-position: center;
    background-repeat: no-repeat;
    background-color: #7c3088; }

.theme-6 .layout-container > header {
  background-color: #9575CD;
  color: #fff; }

.theme-6 .layout-container > main {
  background-color: #ECEFF1; }

.theme-6 .sidebar-header-logo > svg, .theme-6 .sidebar-header-logo > em,
.theme-6 .nav-icon > svg,
.theme-6 .nav-icon > em {
  color: #9575CD !important;
  fill: #ddd3ef !important; }

.theme-7 .layout-container > aside {
  background-color: #fff;
  color: #6C7177; }
  .theme-7 .layout-container > aside > .sidebar-header {
    background-position: center;
    background-repeat: no-repeat;
    background-color: #7c3088; }

.theme-7 .layout-container > header {
  background-color: #455A64;
  color: #fff; }

.theme-7 .layout-container > main {
  background-color: #ECEFF1; }

.theme-8 .layout-container > aside {
  background-color: #F1F2F3;
  color: #fff; }
  .theme-8 .layout-container > aside > .sidebar-header {
    background-position: center;
    background-repeat: no-repeat;
    background-color: #7c3088; }

.theme-8 .layout-container > header {
  background-color: #fff;
  color: #6C7177; }

.theme-8 .layout-container > main {
  background-color: #ECEFF1; }

.theme-8 .sidebar-header-logo > svg, .theme-8 .sidebar-header-logo > em,
.theme-8 .nav-icon > svg,
.theme-8 .nav-icon > em {
  color: #448AFF !important;
  fill: #f1f1f1 !important; }

.theme-9 .layout-container > aside {
  background-color: #fff;
  color: #6C7177; }
  .theme-9 .layout-container > aside > .sidebar-header {
    background-position: center;
    background-repeat: no-repeat;
    background-color: #7c3088; }

.theme-9 .layout-container > header {
  background-color: #fff;
  color: #6C7177; }

.theme-9 .layout-container > main {
  background-color: #ECEFF1; }

.theme-9 .sidebar-header-logo > svg, .theme-9 .sidebar-header-logo > em,
.theme-9 .nav-icon > svg,
.theme-9 .nav-icon > em {
  color: #738c98 !important;
  fill: #90A4AE !important; }

.preview-theme-1 .preview-header {
  background-color: #1976D2; }

.preview-theme-1 .preview-sidebar {
  background-color: #fff; }

.preview-theme-1 .preview-content {
  background-color: #f1f2f3; }

.preview-theme-2 .preview-header {
  background-color: #1976D2; }

.preview-theme-2 .preview-sidebar {
  background-color: #F1F2F3; }

.preview-theme-2 .preview-content {
  background-color: #ECEFF1; }

.preview-theme-3 .preview-header {
  background-color: #37474F; }

.preview-theme-3 .preview-sidebar {
  background-color: #F1F2F3; }

.preview-theme-3 .preview-content {
  background-color: #37474F; }

.preview-theme-4 .preview-header {
  background-color: #4CAF50; }

.preview-theme-4 .preview-sidebar {
  background-color: #fff; }

.preview-theme-4 .preview-content {
  background-color: #ECEFF1; }

.preview-theme-5 .preview-header {
  background-color: #03A9F4; }

.preview-theme-5 .preview-sidebar {
  background-color: #fff; }

.preview-theme-5 .preview-content {
  background-color: #ECEFF1; }

.preview-theme-6 .preview-header {
  background-color: #9575CD; }

.preview-theme-6 .preview-sidebar {
  background-color: #fff; }

.preview-theme-6 .preview-content {
  background-color: #ECEFF1; }

.preview-theme-7 .preview-header {
  background-color: #455A64; }

.preview-theme-7 .preview-sidebar {
  background-color: #fff; }

.preview-theme-7 .preview-content {
  background-color: #ECEFF1; }

.preview-theme-8 .preview-header {
  background-color: #fff; }

.preview-theme-8 .preview-sidebar {
  background-color: #F1F2F3; }

.preview-theme-8 .preview-content {
  background-color: #ECEFF1; }

.preview-theme-9 .preview-header {
  background-color: #fff; }

.preview-theme-9 .preview-sidebar {
  background-color: #fff; }

.preview-theme-9 .preview-content {
  background-color: #ECEFF1; }

/**
 * Typography
 */
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em;
  color: inherit; }

h1 > small, h2 > small, h3 > small, h4 > small, h5 > small, h6 > small {
  margin-left: 5px; }

small {
  color: inherit; }

h1 {
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 24px; }

h2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  margin-top: 24px;
  margin-bottom: 24px; }

h3 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 24px; }

h4 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 24px;
  margin-bottom: 16px; }

h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px; }

h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  margin-top: 24px;
  margin-bottom: 16px; }

.blockquote {
  padding: 1rem 2rem;
  margin: 0 0 2rem;
  font-size: 1.75rem;
  border-left: .5rem solid #eee; }
  .blockquote p:last-child {
    margin: 0; }
  .blockquote .small, .blockquote footer, .blockquote small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777; }
    .blockquote .small:before, .blockquote footer:before, .blockquote small:before {
      content: '\2014   \A0'; }

/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 */
.c-checkbox,
.c-radio {
  margin-right: 4px; }
  .c-checkbox input,
  .c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important; }
  .c-checkbox span,
  .c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid rgba(162, 162, 162, 0.36);
    margin-right: 5px;
    text-align: center; }
    .c-checkbox span:before,
    .c-radio span:before {
      margin-left: 0; }
  .c-checkbox:hover span,
  .c-radio:hover span {
    border-color: #448AFF; }

.c-radio span,
.c-checkbox-rounded span {
  border-radius: 500px; }

.c-checkbox span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle; }

.c-checkbox input[type=checkbox]:checked + span:before {
  color: #fff;
  opacity: 1;
  transition: color .3s ease-out; }

.c-checkbox input[type=checkbox]:checked + span {
  border-color: #448AFF;
  background-color: #448AFF; }

.c-checkbox input[type=checkbox]:disabled + span {
  border-color: rgba(162, 162, 162, 0.12) !important;
  background-color: rgba(162, 162, 162, 0.12) !important;
  cursor: not-allowed; }

.c-radio span:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  border-radius: 500px;
  opacity: 0; }

.c-radio input[type=radio]:checked + span:before {
  color: #fff;
  opacity: 1;
  transition: color .3s ease-out; }

.c-radio input[type=radio]:checked + span {
  border-color: #448AFF;
  background-color: rgba(162, 162, 162, 0.12); }
  .c-radio input[type=radio]:checked + span:before {
    background-color: #448AFF; }

.c-radio input[type=radio]:disabled + span {
  border-color: rgba(162, 162, 162, 0.12) !important;
  cursor: not-allowed; }
  .c-radio input[type=radio]:disabled + span:before {
    background-color: rgba(162, 162, 162, 0.12); }

/**
 * Forms - Note Editor
 */
.note-area > textarea {
  background-image: linear-gradient(rgba(162, 162, 162, 0.26) 0.1em, transparent 0.1em);
  background-size: 100% 20px;
  background-color: transparent !important;
  line-height: 20px;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 1px;
  border: none !important; }

.note-area.note-area-margin {
  position: relative; }
  .note-area.note-area-margin > textarea {
    padding-left: 55px; }
  .note-area.note-area-margin:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 45px;
    width: 5px;
    background-color: rgba(255, 179, 0, 0.5); }

/**
 * Forms - Switch
 */
.switch .form-control {
  padding-top: 7px;
  margin-bottom: 0; }

.switch * {
  cursor: pointer; }

.switch input {
  opacity: 0;
  position: absolute;
  z-index: -1; }

.switch {
  margin-right: 8px; }
  .switch span {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 15px;
    background-color: #9e9e9e;
    border: 0;
    border-radius: 8px;
    vertical-align: middle;
    transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1) 0.1s; }
  .switch span:after {
    content: "";
    position: absolute;
    background-color: #fff;
    top: -2px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 0;
    border-radius: 400px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1); }
  .switch.switch-primary input:checked + span {
    background-color: #448AFF; }
    .switch.switch-primary input:checked + span:after {
      background-color: #448AFF; }
  .switch.switch-primary.switch-warn input:checked + span {
    background-color: rgba(68, 138, 255, 0.5); }
  .switch.switch-info input:checked + span {
    background-color: #03A9F4; }
    .switch.switch-info input:checked + span:after {
      background-color: #03A9F4; }
  .switch.switch-info.switch-warn input:checked + span {
    background-color: rgba(3, 169, 244, 0.5); }
  .switch.switch-danger input:checked + span {
    background-color: #F44336; }
    .switch.switch-danger input:checked + span:after {
      background-color: #F44336; }
  .switch.switch-danger.switch-warn input:checked + span {
    background-color: rgba(244, 67, 54, 0.5); }
  .switch.switch-warning input:checked + span {
    background-color: #FFB300; }
    .switch.switch-warning input:checked + span:after {
      background-color: #FFB300; }
  .switch.switch-warning.switch-warn input:checked + span {
    background-color: rgba(255, 179, 0, 0.5); }
  .switch.switch-success input:checked + span {
    background-color: #4CAF50; }
    .switch.switch-success input:checked + span:after {
      background-color: #4CAF50; }
  .switch.switch-success.switch-warn input:checked + span {
    background-color: rgba(76, 175, 80, 0.5); }
  .switch.switch-purple input:checked + span {
    background-color: #7E57C2; }
    .switch.switch-purple input:checked + span:after {
      background-color: #7E57C2; }
  .switch.switch-purple.switch-warn input:checked + span {
    background-color: rgba(126, 87, 194, 0.5); }

.switch input:checked + span {
  background-color: #EC407A; }
  .switch input:checked + span:after {
    background-color: #EC407A; }

.switch.switch-warn input:checked + span {
  background-color: rgba(236, 64, 122, 0.5); }

.switch input:checked + span:after {
  left: 50%; }

.switch input:disabled + span {
  background-color: rgba(158, 158, 158, 0.5) !important; }
  .switch input:disabled + span:after {
    background-color: #fff !important; }

/**
 * React Datepicker
 */
.ui-datepicker .react-datepicker {
  width: 100%;
  border: 0; }
  .ui-datepicker .react-datepicker .react-datepicker__month-container {
    float: none; }

.ui-datepicker .react-datepicker__header {
  background-color: #fff;
  border: 0;
  padding: 0; }

.ui-datepicker .react-datepicker__day-names, .ui-datepicker .react-datepicker__week {
  display: flex;
  justify-content: space-around; }

.ui-datepicker .react-datepicker__day-name, .ui-datepicker .react-datepicker__day, .ui-datepicker .react-datepicker__time-name {
  padding: 0 12px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  width: 2.4rem; }

.ui-datepicker .react-datepicker__day-name {
  font-weight: bold; }

.ui-datepicker .react-datepicker__current-month {
  padding: 16px 12px;
  font-size: 12px; }

.ui-datepicker .react-datepicker__navigation {
  top: 25px; }

.ui-datepicker .react-datepicker__day--today {
  border-radius: 0 !important;
  background-image: none;
  background-color: #E91E63 !important;
  color: #fff !important; }

@charset "UTF-8";
/**
 * Cardbox
 */
.cardbox {
  position: relative;
  border-radius: 3px;
  background-color: #fff;
  color: #4F5256;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 8px; }
  @media only screen and (min-width: 480px) {
    .cardbox {
      margin-bottom: 16px; } }
  @media only screen and (min-width: 992px) {
    .cardbox {
      margin-bottom: 24px; } }
  .cardbox .cardbox-heading {
    padding: 16px;
    margin: 0; }
    .cardbox .cardbox-heading > .cardbox-title {
      margin: 0;
      font-size: 18px; }
    .cardbox .cardbox-heading > .cardbox-icon {
      float: right;
      color: rgba(255, 255, 255, 0.87);
      font-size: 20px; }
    .cardbox .cardbox-heading > small {
      color: rgba(162, 162, 162, 0.92);
      letter-spacing: .01em; }
  .cardbox .cardbox-body {
    position: relative;
    padding: 16px; }
  .cardbox .cardbox-footer {
    padding: 16px;
    border-top: 1px solid rgba(162, 162, 162, 0.12); }
  .cardbox .cardbox-item {
    position: relative;
    display: block;
    min-height: 120px; }
    .cardbox .cardbox-item > .cardbox-item-text {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.35);
      margin: 0;
      color: #fff;
      padding: 8px; }
      .cardbox .cardbox-item > .cardbox-item-text a {
        color: inherit; }
    .cardbox .cardbox-item > .cardbox-item-image {
      display: block;
      width: 100%;
      height: 190px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover; }
    .cardbox .cardbox-item.cardbox-media {
      min-height: 280px;
      background-repeat: repeat;
      background-position: 50% 50%;
      background-size: cover;
      background-attachment: scroll;
      background-origin: padding-box; }
      .cardbox .cardbox-item.cardbox-media .cardbox-media-quote {
        padding: 16px;
        font-size: 35px; }
        @media only screen and (min-width: 768px) {
          .cardbox .cardbox-item.cardbox-media .cardbox-media-quote {
            font-size: 45px; } }
        .cardbox .cardbox-item.cardbox-media .cardbox-media-quote > a {
          color: inherit;
          text-decoration: none; }
        .cardbox .cardbox-item.cardbox-media .cardbox-media-quote:before {
          content: '\201C';
          display: block;
          font-size: 2em;
          line-height: 1;
          margin-top: 0.25em; }
  .cardbox.cardbox-transparent {
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0 #000; }
  .cardbox .cardbox-offset {
    position: relative;
    padding-bottom: 36px;
    z-index: 10; }
    .cardbox .cardbox-offset > .cardbox-offset-item {
      position: absolute;
      top: -24px;
      left: 15px;
      right: 15px; }
  .cardbox .cardbox-toolbar {
    position: relative;
    width: 100%;
    min-height: 64px;
    font-size: 18px;
    line-height: 64px;
    padding-left: 22px;
    z-index: 2; }
  .cardbox .cardbox-subheader {
    padding: 16px 0 16px 16px;
    line-height: .75em;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .01em;
    color: rgba(0, 0, 0, 0.54); }
    .cardbox .cardbox-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-text {
      padding-top: 16px; }
    .cardbox .cardbox-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-img,
    .cardbox .cardbox-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-icon,
    .cardbox .cardbox-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-initial {
      margin-top: 10px; }
  .cardbox .cardbox-divider {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.12); }
    .cardbox .cardbox-divider + .cardbox-offset {
      margin-top: -10px; }
  .cardbox > .ui-datepicker,
  .cardbox > .ui-datepicker-responsive > .ui-datepicker {
    width: 100%;
    box-shadow: 0 0 0 #000;
    margin: 0; }
    .cardbox > .ui-datepicker > table,
    .cardbox > .ui-datepicker-responsive > .ui-datepicker > table {
      width: 100%; }
  .cardbox .editable-wrap {
    width: 100%; }
  .cardbox > .list-group > .list-group-item {
    border-left: 0;
    border-right: 0; }
    .cardbox > .list-group > .list-group-item:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .cardbox > .list-group > .list-group-item:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 0; }
  .cardbox > .table-responsive > .table,
  .cardbox > .table {
    margin-bottom: 0; }
  .cardbox > .table-responsive {
    border: 0; }
  .cardbox > .btn {
    border-radius: 0;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center; }
    .cardbox > .btn:last-child {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px; }
  .cardbox.cardbox-map {
    min-height: 280px; }
    .cardbox.cardbox-map .cardbox-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border: 0;
      background-color: transparent; }

/**
 * Google Material Colors (customization)
 */
.bg-pink-500 {
  background-color: #E91E63;
  color: #fff !important; }

.bg-purple-400 {
  background-color: #AB47BC;
  color: #fff !important; }

.bg-purple-500 {
  background-color: #9C27B0;
  color: #fff !important; }

.bg-deep-purple-500 {
  background-color: #673AB7;
  color: #fff !important; }

.bg-indigo-500 {
  background-color: #3F51B5;
  color: #fff !important; }

.bg-green-50 {
  background-color: #E8F5E9;
  color: #fff !important; }

.bg-green-500 {
  background-color: #4CAF50;
  color: #fff !important; }

.bg-blue-500 {
  background-color: #2196F3;
  color: #fff !important; }

.bg-blue-grey-900 {
  background-color: #F1F2F3;
  color: #fff !important; }

.bg-red-500 {
  background-color: #F44336;
  color: #fff !important; }

.bg-primary,
.bg-red-800, .bg-red-900,
.bg-pink-700, .bg-pink-800, .bg-pink-900,
.bg-purple, .bg-purple-500, .bg-purple-600, .bg-purple-700, .bg-purple-800, .bg-purple-900,
.bg-deep-purple, .bg-deep-purple-500, .bg-deep-purple-600, .bg-deep-purple-700, .bg-deep-purple-800, .bg-deep-purple-900, .bg-deep-purple-a400, .bg-deep-purple-a700,
.bg-indigo, .bg-indigo-500, .bg-indigo-600, .bg-indigo-700, .bg-indigo-800, .bg-indigo-900, .bg-indigo-a700, .bg-blue-800,
.bg-blue-900, .bg-light-blue-900,
.bg-cyan-900, .bg-teal-800, .bg-teal-900,
.bg-green-800, .bg-green-900,
.bg-brown, .bg-brown-500, .bg-brown-600, .bg-brown-700, .bg-brown-800, .bg-brown-900, .bg-blue-grey-600,
.bg-blue-grey-700, .bg-blue-grey-800, .bg-blue-grey-900, .bg-grey-600,
.bg-grey-700, .bg-grey-800, .bg-grey-900 {
  color: rgba(255, 255, 255, 0.97); }
  .bg-primary .text-muted,
  .bg-red-800 .text-muted, .bg-red-900 .text-muted,
  .bg-pink-700 .text-muted, .bg-pink-800 .text-muted, .bg-pink-900 .text-muted,
  .bg-purple .text-muted, .bg-purple-500 .text-muted, .bg-purple-600 .text-muted, .bg-purple-700 .text-muted, .bg-purple-800 .text-muted, .bg-purple-900 .text-muted,
  .bg-deep-purple .text-muted, .bg-deep-purple-500 .text-muted, .bg-deep-purple-600 .text-muted, .bg-deep-purple-700 .text-muted, .bg-deep-purple-800 .text-muted, .bg-deep-purple-900 .text-muted, .bg-deep-purple-a400 .text-muted, .bg-deep-purple-a700 .text-muted,
  .bg-indigo .text-muted, .bg-indigo-500 .text-muted, .bg-indigo-600 .text-muted, .bg-indigo-700 .text-muted, .bg-indigo-800 .text-muted, .bg-indigo-900 .text-muted, .bg-indigo-a700 .text-muted, .bg-blue-800 .text-muted,
  .bg-blue-900 .text-muted, .bg-light-blue-900 .text-muted,
  .bg-cyan-900 .text-muted, .bg-teal-800 .text-muted, .bg-teal-900 .text-muted,
  .bg-green-800 .text-muted, .bg-green-900 .text-muted,
  .bg-brown .text-muted, .bg-brown-500 .text-muted, .bg-brown-600 .text-muted, .bg-brown-700 .text-muted, .bg-brown-800 .text-muted, .bg-brown-900 .text-muted, .bg-blue-grey-600 .text-muted,
  .bg-blue-grey-700 .text-muted, .bg-blue-grey-800 .text-muted, .bg-blue-grey-900 .text-muted, .bg-grey-600 .text-muted,
  .bg-grey-700 .text-muted, .bg-grey-800 .text-muted, .bg-grey-900 .text-muted {
    color: rgba(255, 255, 255, 0.57) !important; }
  .bg-primary .text-soft,
  .bg-red-800 .text-soft, .bg-red-900 .text-soft,
  .bg-pink-700 .text-soft, .bg-pink-800 .text-soft, .bg-pink-900 .text-soft,
  .bg-purple .text-soft, .bg-purple-500 .text-soft, .bg-purple-600 .text-soft, .bg-purple-700 .text-soft, .bg-purple-800 .text-soft, .bg-purple-900 .text-soft,
  .bg-deep-purple .text-soft, .bg-deep-purple-500 .text-soft, .bg-deep-purple-600 .text-soft, .bg-deep-purple-700 .text-soft, .bg-deep-purple-800 .text-soft, .bg-deep-purple-900 .text-soft, .bg-deep-purple-a400 .text-soft, .bg-deep-purple-a700 .text-soft,
  .bg-indigo .text-soft, .bg-indigo-500 .text-soft, .bg-indigo-600 .text-soft, .bg-indigo-700 .text-soft, .bg-indigo-800 .text-soft, .bg-indigo-900 .text-soft, .bg-indigo-a700 .text-soft, .bg-blue-800 .text-soft,
  .bg-blue-900 .text-soft, .bg-light-blue-900 .text-soft,
  .bg-cyan-900 .text-soft, .bg-teal-800 .text-soft, .bg-teal-900 .text-soft,
  .bg-green-800 .text-soft, .bg-green-900 .text-soft,
  .bg-brown .text-soft, .bg-brown-500 .text-soft, .bg-brown-600 .text-soft, .bg-brown-700 .text-soft, .bg-brown-800 .text-soft, .bg-brown-900 .text-soft, .bg-blue-grey-600 .text-soft,
  .bg-blue-grey-700 .text-soft, .bg-blue-grey-800 .text-soft, .bg-blue-grey-900 .text-soft, .bg-grey-600 .text-soft,
  .bg-grey-700 .text-soft, .bg-grey-800 .text-soft, .bg-grey-900 .text-soft {
    color: rgba(255, 255, 255, 0.26); }

.bg-red, .bg-red-500, .bg-red-600, .bg-red-700, .bg-red-a200, .bg-red-a400, .bg-red-a700, .bg-pink,
.bg-pink-500, .bg-pink-600, .bg-pink-a200, .bg-pink-a400, .bg-pink-a700, .bg-purple-300,
.bg-purple-400, .bg-purple-a200, .bg-purple-a400, .bg-purple-a700, .bg-deep-purple-300,
.bg-deep-purple-400, .bg-deep-purple-a200,
.bg-indigo-300, .bg-indigo-400, .bg-indigo-a200, .bg-indigo-a400, .bg-blue,
.bg-blue-500, .bg-blue-600, .bg-blue-700, .bg-blue-a200, .bg-blue-a400, .bg-blue-a700, .bg-light-blue,
.bg-light-blue-500, .bg-light-blue-600, .bg-light-blue-700, .bg-light-blue-800, .bg-light-blue-a700, .bg-cyan,
.bg-cyan-500, .bg-cyan-600, .bg-cyan-700, .bg-cyan-800, .bg-teal,
.bg-teal-500, .bg-teal-600, .bg-teal-700,
.bg-green, .bg-green-500, .bg-green-600, .bg-green-700, .bg-light-green-800, .bg-light-green-900,
.bg-lime-900, .bg-orange-800, .bg-orange-900,
.bg-deep-orange, .bg-deep-orange-500, .bg-deep-orange-600, .bg-deep-orange-700, .bg-deep-orange-800, .bg-deep-orange-900, .bg-deep-orange-a400, .bg-deep-orange-a700, .bg-brown-300,
.bg-brown-400, .bg-blue-grey, .bg-blue-grey-400, .bg-blue-grey-500 {
  color: #fff; }
  .bg-red .text-muted, .bg-red-500 .text-muted, .bg-red-600 .text-muted, .bg-red-700 .text-muted, .bg-red-a200 .text-muted, .bg-red-a400 .text-muted, .bg-red-a700 .text-muted, .bg-pink .text-muted,
  .bg-pink-500 .text-muted, .bg-pink-600 .text-muted, .bg-pink-a200 .text-muted, .bg-pink-a400 .text-muted, .bg-pink-a700 .text-muted, .bg-purple-300 .text-muted,
  .bg-purple-400 .text-muted, .bg-purple-a200 .text-muted, .bg-purple-a400 .text-muted, .bg-purple-a700 .text-muted, .bg-deep-purple-300 .text-muted,
  .bg-deep-purple-400 .text-muted, .bg-deep-purple-a200 .text-muted,
  .bg-indigo-300 .text-muted, .bg-indigo-400 .text-muted, .bg-indigo-a200 .text-muted, .bg-indigo-a400 .text-muted, .bg-blue .text-muted,
  .bg-blue-500 .text-muted, .bg-blue-600 .text-muted, .bg-blue-700 .text-muted, .bg-blue-a200 .text-muted, .bg-blue-a400 .text-muted, .bg-blue-a700 .text-muted, .bg-light-blue .text-muted,
  .bg-light-blue-500 .text-muted, .bg-light-blue-600 .text-muted, .bg-light-blue-700 .text-muted, .bg-light-blue-800 .text-muted, .bg-light-blue-a700 .text-muted, .bg-cyan .text-muted,
  .bg-cyan-500 .text-muted, .bg-cyan-600 .text-muted, .bg-cyan-700 .text-muted, .bg-cyan-800 .text-muted, .bg-teal .text-muted,
  .bg-teal-500 .text-muted, .bg-teal-600 .text-muted, .bg-teal-700 .text-muted,
  .bg-green .text-muted, .bg-green-500 .text-muted, .bg-green-600 .text-muted, .bg-green-700 .text-muted, .bg-light-green-800 .text-muted, .bg-light-green-900 .text-muted,
  .bg-lime-900 .text-muted, .bg-orange-800 .text-muted, .bg-orange-900 .text-muted,
  .bg-deep-orange .text-muted, .bg-deep-orange-500 .text-muted, .bg-deep-orange-600 .text-muted, .bg-deep-orange-700 .text-muted, .bg-deep-orange-800 .text-muted, .bg-deep-orange-900 .text-muted, .bg-deep-orange-a400 .text-muted, .bg-deep-orange-a700 .text-muted, .bg-brown-300 .text-muted,
  .bg-brown-400 .text-muted, .bg-blue-grey .text-muted, .bg-blue-grey-400 .text-muted, .bg-blue-grey-500 .text-muted {
    color: rgba(255, 255, 255, 0.57) !important; }
  .bg-red .text-soft, .bg-red-500 .text-soft, .bg-red-600 .text-soft, .bg-red-700 .text-soft, .bg-red-a200 .text-soft, .bg-red-a400 .text-soft, .bg-red-a700 .text-soft, .bg-pink .text-soft,
  .bg-pink-500 .text-soft, .bg-pink-600 .text-soft, .bg-pink-a200 .text-soft, .bg-pink-a400 .text-soft, .bg-pink-a700 .text-soft, .bg-purple-300 .text-soft,
  .bg-purple-400 .text-soft, .bg-purple-a200 .text-soft, .bg-purple-a400 .text-soft, .bg-purple-a700 .text-soft, .bg-deep-purple-300 .text-soft,
  .bg-deep-purple-400 .text-soft, .bg-deep-purple-a200 .text-soft,
  .bg-indigo-300 .text-soft, .bg-indigo-400 .text-soft, .bg-indigo-a200 .text-soft, .bg-indigo-a400 .text-soft, .bg-blue .text-soft,
  .bg-blue-500 .text-soft, .bg-blue-600 .text-soft, .bg-blue-700 .text-soft, .bg-blue-a200 .text-soft, .bg-blue-a400 .text-soft, .bg-blue-a700 .text-soft, .bg-light-blue .text-soft,
  .bg-light-blue-500 .text-soft, .bg-light-blue-600 .text-soft, .bg-light-blue-700 .text-soft, .bg-light-blue-800 .text-soft, .bg-light-blue-a700 .text-soft, .bg-cyan .text-soft,
  .bg-cyan-500 .text-soft, .bg-cyan-600 .text-soft, .bg-cyan-700 .text-soft, .bg-cyan-800 .text-soft, .bg-teal .text-soft,
  .bg-teal-500 .text-soft, .bg-teal-600 .text-soft, .bg-teal-700 .text-soft,
  .bg-green .text-soft, .bg-green-500 .text-soft, .bg-green-600 .text-soft, .bg-green-700 .text-soft, .bg-light-green-800 .text-soft, .bg-light-green-900 .text-soft,
  .bg-lime-900 .text-soft, .bg-orange-800 .text-soft, .bg-orange-900 .text-soft,
  .bg-deep-orange .text-soft, .bg-deep-orange-500 .text-soft, .bg-deep-orange-600 .text-soft, .bg-deep-orange-700 .text-soft, .bg-deep-orange-800 .text-soft, .bg-deep-orange-900 .text-soft, .bg-deep-orange-a400 .text-soft, .bg-deep-orange-a700 .text-soft, .bg-brown-300 .text-soft,
  .bg-brown-400 .text-soft, .bg-blue-grey .text-soft, .bg-blue-grey-400 .text-soft, .bg-blue-grey-500 .text-soft {
    color: rgba(255, 255, 255, 0.26); }

/**
 * Float Button
 */
.floatbutton {
  position: relative;
  z-index: 1000; }
  .floatbutton .mfb-component__button--child,
  .floatbutton .mfb-component__button--main {
    color: #fff; }

/**
 * Material Forms
 */
.mda-form-group {
  position: relative;
  padding: 0 0 24px 0;
  flex: 1 1 auto; }

.mda-form-control {
  position: relative;
  padding-top: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  flex: 1 1 auto; }

.mda-form-control > input, .mda-form-control > textarea, .mda-form-control > select {
  padding: 2px;
  height: 34px;
  background-color: transparent !important;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  width: 100% !important; }
  .mda-form-control > input:focus ~ label, .mda-form-control > input.focus ~ label, .mda-form-control > textarea:focus ~ label, .mda-form-control > textarea.focus ~ label, .mda-form-control > select:focus ~ label, .mda-form-control > select.focus ~ label {
    top: 0 !important;
    font-size: 0.85em !important;
    color: #448AFF;
    opacity: 1; }
  .mda-form-control > input ~ label, .mda-form-control > textarea ~ label, .mda-form-control > select ~ label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: inline-block;
    font-size: .85em;
    opacity: .5;
    font-weight: 500;
    transition: all .2s ease; }
  .mda-form-control > input.disabled, .mda-form-control > input[disabled], .mda-form-control > textarea.disabled, .mda-form-control > textarea[disabled], .mda-form-control > select.disabled, .mda-form-control > select[disabled] {
    background-color: transparent;
    opacity: .5;
    cursor: not-allowed; }

.mda-form-control.mda-form-control-dark {
  border-bottom-color: rgba(255, 255, 255, 0.26); }
  .mda-form-control.mda-form-control-dark > input, .mda-form-control.mda-form-control-dark > textarea, .mda-form-control.mda-form-control-dark > select {
    color: #fff; }
    .mda-form-control.mda-form-control-dark > input:focus ~ label,
    .mda-form-control.mda-form-control-dark > input.focus ~ label, .mda-form-control.mda-form-control-dark > textarea:focus ~ label,
    .mda-form-control.mda-form-control-dark > textarea.focus ~ label, .mda-form-control.mda-form-control-dark > select:focus ~ label,
    .mda-form-control.mda-form-control-dark > select.focus ~ label {
      color: #fff !important; }

.mda-form-group-lg .mda-form-control > input {
  height: 48px; }

.mda-form-group-lg.float-label .mda-form-control > input ~ label {
  top: 28px;
  font-size: 1.5em; }

.mda-form-control > textarea {
  height: auto; }

.mda-form-control-line {
  position: absolute;
  bottom: -1px;
  left: 50%;
  right: 50%;
  height: 2px;
  transition: all .3s ease; }

.mda-form-control > input:focus ~ .mda-form-control-line, .mda-form-control > textarea:focus ~ .mda-form-control-line, .mda-form-control > select:focus ~ .mda-form-control-line {
  background-color: #448AFF;
  left: 0;
  right: 0; }

.mda-form-control.mda-form-control-dark > input:focus ~ .mda-form-control-line, .mda-form-control.mda-form-control-dark > textarea:focus ~ .mda-form-control-line, .mda-form-control.mda-form-control-dark > select:focus ~ .mda-form-control-line {
  background-color: #fff; }

.mda-form-msg {
  position: absolute;
  bottom: 0;
  font-size: .85em;
  line-height: 24px;
  font-weight: 300;
  color: #90A4AE; }
  .mda-form-msg.right {
    right: 0; }

.form-validate .mda-form-control .form-control.error ~ .mda-form-control-line {
  background-color: #F44336; }

.form-validate .mda-form-control .form-control.error:focus ~ label,
.form-validate .mda-form-control .form-control.error.focus ~ label,
.form-validate .mda-form-control .form-control.error ~ .mda-form-msg {
  color: #F44336; }

.form-validate .mda-form-control .form-control.valid ~ .mda-form-control-line,
.form-validate .mda-form-control .form-control.valid:focus ~ .mda-form-control-line {
  background-color: #4CAF50; }

.form-validate .mda-form-control .form-control.valid:focus ~ label,
.form-validate .mda-form-control .form-control.valid.focus ~ label,
.form-validate .mda-form-control .form-control.valid:focus:focus ~ label,
.form-validate .mda-form-control .form-control.valid:focus.focus ~ label {
  color: #4CAF50; }

.form-validate label.error {
  color: #F44336; }

.form-validate label.valid {
  color: #4CAF50; }

.float-label .mda-form-control > input ~ label,
.float-label .mda-form-control > textarea ~ label {
  top: 15px;
  margin-bottom: 0;
  font-size: 1em;
  pointer-events: none; }

.float-label .mda-form-control > input.valid ~ label,
.float-label .mda-form-control > input.error ~ label,
.float-label .mda-form-control > input.has-value ~ label,
.float-label .mda-form-control > textarea.valid ~ label,
.float-label .mda-form-control > textarea.error ~ label,
.float-label .mda-form-control > textarea.has-value ~ label {
  top: 0;
  font-size: .85em; }

.mda-input-group {
  position: relative; }
  .mda-input-group .mda-form-control > input {
    padding-left: 36px; }
  .mda-input-group .mda-form-control > label {
    margin-left: 36px; }
  .mda-input-group .form-control {
    float: none; }
  .mda-input-group > .mda-input-group-addon {
    position: absolute;
    top: 25px;
    left: 10px; }
  .mda-input-group .mda-form-group + .input-group-btn,
  .mda-input-group .input-group-btn + .mda-form-group {
    display: block;
    padding-left: 16px; }

.mda-checkbox,
.mda-radio {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin: 0;
  cursor: pointer; }
  .mda-checkbox input,
  .mda-radio input {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    margin-left: -20px;
    cursor: pointer;
    opacity: 0; }
    .mda-checkbox input:checked + em:before,
    .mda-radio input:checked + em:before {
      background-color: inherit;
      border-width: 0; }
    .mda-checkbox input:checked + span .active,
    .mda-radio input:checked + span .active {
      display: inherit; }
  .mda-checkbox input[type="checkbox"]:checked + em:after,
  .mda-radio input[type="checkbox"]:checked + em:after {
    position: absolute;
    top: 2px;
    left: 6px;
    display: table;
    width: 6px;
    height: 12px;
    border: 2px solid;
    border-top: 0;
    border-left: 0;
    content: ' ';
    transform: rotate(45deg); }
  .mda-checkbox input[type="radio"]:checked + em:after,
  .mda-radio input[type="radio"]:checked + em:after {
    content: ' ';
    position: absolute;
    top: 3px;
    left: 3px;
    display: table;
    width: 12px;
    height: 12px;
    background: inherit;
    border-radius: 50%;
    border: 2px solid #fff; }
  .mda-checkbox input[type="radio"] + em,
  .mda-checkbox input[type="radio"] + em:before,
  .mda-radio input[type="radio"] + em,
  .mda-radio input[type="radio"] + em:before {
    border-radius: 50%; }

.mda-checkbox input[disabled] + em:before,
fieldset[disabled] .mda-checkbox input + em:before,
.mda-radio input[disabled] + em:before,
fieldset[disabled] .mda-radio input + em:before {
  border-color: rgba(0, 0, 0, 0.26); }

.mda-checkbox input[disabled]:checked + em,
.mda-radio input[disabled]:checked + em {
  background-color: rgba(0, 0, 0, 0.26); }

.mda-radio input[disabled]:checked + em:after {
  background-color: transparent; }

.mda-checkbox input[disabled]:checked + em:before {
  background-color: rgba(0, 0, 0, 0.26); }

.mda-checkbox > em,
.mda-radio > em {
  position: relative;
  display: inline-block;
  width: 0;
  height: 18px;
  margin-top: -2px;
  margin-right: 28px;
  margin-left: -20px;
  line-height: 1;
  vertical-align: middle; }
  .mda-checkbox > em:before,
  .mda-radio > em:before {
    content: "";
    position: absolute;
    width: 18px;
    height: 100%;
    border: 2px solid rgba(162, 162, 162, 0.56);
    border-radius: 2px;
    transition: all .2s; }
  .mda-checkbox > em.empty:after,
  .mda-radio > em.empty:after {
    display: none !important; }

.unauthorized-content {
  display: grid;
  justify-content: center;
  align-content: center;
  min-height: 95vh;
}

.unauthorized-content h2 {
  text-align: center;
  font-family: 'roboto';
}

.unauthorized-image {
  width: 60%;
  text-align: center;
  margin: auto;
}

/**
 * Ripple
 */
.ripple {
  position: relative;
  overflow: hidden; }
  .ripple > .md-ripple {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    transform: scale(0.0001, 0.0001);
    opacity: 1;
    width: 0;
    height: 0; }
    .ripple > .md-ripple.md-ripple-animate {
      transition: transform 0.5s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1);
      opacity: 0;
      transform: scale(2); }
  .ripple.btn-oval > .md-ripple.md-ripple-animate, .ripple.btn-pill-left > .md-ripple.md-ripple-animate, .ripple.btn-pill-right > .md-ripple.md-ripple-animate, .ripple.btn-flat-icon > .md-ripple.md-ripple-animate, .ripple.btn-circle > .md-ripple.md-ripple-animate {
    transform: scale(0.99); }

